import {
  closeIcon,
  whiteArrowLeft,
  whiteArrowRight,
} from "@app/theme/icons/commonIcons";
import { LightBox } from "@sphtech/dmg-design-system";
import { createPortal } from "react-dom";

import {
  extractCrop,
  getGalleryItemInfo,
  ResolvedCUEElement,
  ResolvedEmbedCUEElement,
} from "../elements";

const LightBoxPortal = ({
  gotoItem,
  isOpen,
  close,
  elements,
  ImuInsertAt = 2,
}: {
  gotoItem: number;
  isOpen: boolean;
  close: () => void;
  elements: ResolvedCUEElement[];
  ImuInsertAt?: number;
}) => {
  if (!elements.length || !isOpen) return null;

  const gallery_items = ["gallery_items_image", "gallery_items_custom_html"];

  const galleryFields = elements
    .filter((item) => gallery_items.includes(item.type))
    .map((gallery_item) => {
      const info = getGalleryItemInfo(
        [gallery_item].concat(gallery_item.children || []),
      );
      const embed = gallery_item.children?.find(
        (item): item is ResolvedEmbedCUEElement => item.type === "embed",
      );
      const embedUri = embed?.additionalFields.uri?.value;

      const aspectRatio = () => {
        const ratio = {
          srcWidth: 0,
          srcHeight: 0,
        };
        if (embedUri) {
          if (
            embedUri.includes("youtube.com/shorts/") ||
            embedUri.includes("instagram")
          ) {
            ratio.srcWidth = 4;
            ratio.srcHeight = 5;
          } else if (embedUri.includes("tiktok")) {
            ratio.srcWidth = 0;
            ratio.srcHeight = 0;
          } else {
            ratio.srcWidth = 16;
            ratio.srcHeight = 9;
          }
        }
        return ratio;
      };

      const image =
        gallery_item.type === "gallery_items_image"
          ? extractCrop(gallery_item.relation, "original")
          : undefined;

      return {
        caption: info?.title?.value || "",
        targetUrl: info?.targetUrl?.value,
        videoUrl: embedUri,
        image,
        shareUrl: "",
        alt: image?.alt || "",
        ...aspectRatio(),
      };
    });

  const onSlideChange = (n: number, s: string) => {
    window.postMessage(`lightbox-change=${n}`);
    window.postMessage({
      gallery: { action: s, slide: n },
    });
  };

  const onSlideClick = (n: number, s: string) => {
    window.postMessage({
      gallery: { action: s, slide: n },
    });
  };

  return createPortal(
    <LightBox
      items={galleryFields}
      gotoItem={gotoItem}
      isOpen={isOpen}
      toggle={close}
      lightboxContainer={{
        leftIcon: whiteArrowLeft,
        rightIcon: whiteArrowRight,
        closeButton: closeIcon,
      }}
      ImuInsertAt={ImuInsertAt}
      onSlideChange={onSlideChange}
      onSlideClick={onSlideClick}
    />,
    document.body,
  );
};

export default LightBoxPortal;
