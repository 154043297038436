import React from "react";
import styles from "./dateLine.module.css";

export type DateLabelProps = {
  children?: React.ReactNode;
};

function DateLine({ children }: DateLabelProps): React.ReactElement {
  return <p className={styles.dateline}>{children}</p>;
}

export default DateLine;
