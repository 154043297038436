import { useCallback, useEffect, useState } from "react";

type useListGroupProps = {
  listGroupRef: React.RefObject<HTMLDivElement>;
  activeTitle?: string;
};

export default function useListGroup({
  listGroupRef,
  activeTitle,
}: useListGroupProps) {
  const [hasPrev, setHasPrev] = useState(true);
  const [hasNext, setHasNext] = useState(true);

  const onScrollAndResize = useCallback(() => {
    if (!listGroupRef.current) return;
    const { clientWidth, scrollWidth, scrollLeft } = listGroupRef.current;
    const scrollRight = scrollWidth - scrollLeft - clientWidth;

    setHasPrev(true);
    setHasNext(true);

    if (scrollLeft === 0) {
      setHasPrev(false);
    }
    // Needs some allowance for right arrow
    if (scrollRight < 50) {
      setHasNext(false);
    }
  }, [listGroupRef]);

  useEffect(() => {
    if (!listGroupRef.current) return;
    const LGContainer = listGroupRef.current;

    const LGArray = Array.from(LGContainer.children);
    const foundItem = LGArray.find(
      (el) => el.innerHTML === activeTitle?.replace(/&/g, "&amp;"),
    );

    // To prevent vertical scrolling on page load
    if (LGContainer.getBoundingClientRect().top < window.innerHeight) {
      setTimeout(() => {
        foundItem?.scrollIntoView({
          behavior: "smooth",
          block: "nearest", // Horizontal scrolling only
          inline: "center",
        });
      }, 200);
    }

    window.addEventListener("resize", onScrollAndResize, { passive: true });
    LGContainer.addEventListener("scroll", onScrollAndResize, {
      passive: true,
    });
    onScrollAndResize();
    return () => {
      window.removeEventListener("resize", onScrollAndResize);
      LGContainer?.removeEventListener("scroll", onScrollAndResize);
    };
  }, [activeTitle, listGroupRef, onScrollAndResize]);

  return { hasPrev, hasNext };
}
