import { makeSvgDataSrc } from "@sphtech/dmg-design-system";

// import landscapeSrc from "./landscape.svg";

const fallbackSvg = makeSvgDataSrc({
  width: 200,
  height: 200,
  text: "PEAK SINGAPORE",
});

const peakPlaceHolder = {
  landscape: {
    src: fallbackSvg,
    srcWidth: 1080,
    srcHeight: 720,
    alt: "",
  },
  portrait: {
    src: fallbackSvg,
    srcWidth: 768,
    srcHeight: 1024,
    alt: "",
  },
};

export default peakPlaceHolder;
