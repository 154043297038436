import { configPermutive } from "@pub/config";
import { loadScript } from "@sphtech/dmg-design-system";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

export default configPermutive.active ? AnalyticsPermutive : () => null;

function AnalyticsPermutive() {
  useEffect(() => {
    const permutiveFile = `https://${configPermutive.organisationId}.edge.permutive.app/${configPermutive.workspaceId}-web.js`;
    void loadScript(permutiveFile, true);
  }, []);
  return (
    <Helmet>
      <script>
        {`
          !function(e,o,n,i){if(!e){e=e||{},window.permutive=e,e.q=[];var t=function(){return([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,function(e){return(e^(window.crypto||window.msCrypto).getRandomValues(new Uint8Array(1))[0]&15>>e/4).toString(16)})};e.config=i||{},e.config.apiKey=o,e.config.workspaceId=n,e.config.environment=e.config.environment||"production",(window.crypto||window.msCrypto)&&(e.config.viewId=t());for(var g=["addon","identify","track","trigger","query","segment","segments","ready","on","once","user","consent"],r=0;r<g.length;r++){var w=g[r];e[w]=function(o){return function(){var n=Array.prototype.slice.call(arguments,0);e.q.push({functionName:o,arguments:n})}}(w)}}}(window.permutive,"${configPermutive.workspaceKey}","${configPermutive.workspaceId}",{});
          window.googletag=window.googletag||{},window.googletag.cmd=window.googletag.cmd||[],window.googletag.cmd.push(function(){if(0===window.googletag.pubads().getTargeting("permutive").length){var e=window.localStorage.getItem("_pdfps");window.googletag.pubads().setTargeting("permutive",e?JSON.parse(e):[]);var o=window.localStorage.getItem("permutive-id");o&&(window.googletag.pubads().setTargeting("puid",o),window.googletag.pubads().setTargeting("ptime",Date.now().toString())),window.permutive.config.viewId&&window.googletag.pubads().setTargeting("prmtvvid",window.permutive.config.viewId),window.permutive.config.workspaceId&&window.googletag.pubads().setTargeting("prmtvwid",window.permutive.config.workspaceId)}})
        `}
      </script>
      <script>
        {`
          (function(){
            function sphpCookie() {
              var cookieName = 'suid';
              var cookieArr = document.cookie.split(";");
              for(var i = 0; i < cookieArr.length; i++) {
                var cookiePair = cookieArr[i].split("=");
                if(cookieName == cookiePair[0].trim()) {
                    return decodeURIComponent(cookiePair[1]);
                }
              }
              return null;
            }
            var sphp_data = {
              contentcat: "free",
              pagination: 1,
              visitorcat: "anonymous",
            };
            var sphm_base_data = window.sphm_base_data || {};
            if (sphm_base_data?.content_level_1) sphp_data.chapter1 = sphm_base_data.content_level_1;
            if (sphm_base_data?.content_level_2) sphp_data.chapter2 = sphm_base_data.content_level_2;
            if (sphm_base_data?.content_level_3) sphp_data.chapter3 = sphm_base_data.content_level_3;
            if (sphm_base_data?.articleId) sphp_data.articleid = sphm_base_data.articleId;
            if (sphm_base_data?.article_keyword) sphp_data.keywords = sphm_base_data.article_keyword.split(",");
            if (sphm_base_data?.content_type) sphp_data.contenttype = sphm_base_data.content_type;
            if (typeof(window.gpt_grapeshots) != 'undefined' && Array.isArray(window.gpt_grapeshots)) {
              sphp_data.gsKeywords = window.gpt_grapeshots.filter((s) => typeof(s) == 'string').map((s) => s.toLowerCase());;
            }
            var sSUID = sphpCookie();
            if (!!sSUID) {
              permutive.identify([{
                'id': sSUID,
                'tag': 'suid',
                'priority': 1
              }]);
            }
            permutive.addon('web', { page: sphp_data });
          })();
        `}
      </script>
    </Helmet>
  );
}
