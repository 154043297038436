import PubBaseLayout from "@pub/BaseLayout";
import { configNewsletter } from "@pub/config";
import MetaData from "@src/app/components/Modules/MetaData";
import AppNewsletter from "@src/app/components/Modules/Newsletter/AppNewsletter";
import { ReactElement } from "react";

import styles from "./newslettersignup.module.css";

export default function NewsletterSignupPage(): ReactElement {
  const newsLetterProps = {
    backgroundImage: configNewsletter.image || "",
    newsletterCtaText: "SIGN UP",
    emailPlaceHolder: {
      placeholder: "Email Address",
    },
    newsletterBody: configNewsletter.body || "",
    newsletterTitle: configNewsletter.title || "",
  };
  return (
    <PubBaseLayout ads={false}>
      <MetaData title="Newsletter" type="WebPage" path="/newsletter-signup" />
      <div className={styles.newsletterOuterContainer}>
        <AppNewsletter {...newsLetterProps} variant="standalone" />
      </div>
    </PubBaseLayout>
  );
}
