import { MouseEvent, ReactNode } from "react";
import styles from "./index.module.css";
import cx from "classnames";
import { useCustomClassContext } from "../../../hooks/useCustomClassContext/CustomClassContext";

type Props = {
  href?: string;
  target?: HTMLAnchorElement["target"];
  type?: HTMLButtonElement["type"];
  onClick?: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  variant: "primary" | "secondary";
  icon?: ReactNode;
  iconPlacement?: "before" | "after";
  disabled?: boolean;
  children?: ReactNode;
  rel?: HTMLAnchorElement["rel"];
};

export default function Button({
  children,
  variant,
  icon,
  iconPlacement = "before",
  ...rest
}: Props) {
  const customClass = useCustomClassContext();

  const props = {
    className: cx(styles.base, styles[variant], customClass?.button?.[variant]),
    ...rest,
  };

  const childElements = icon ? (
    <>
      {iconPlacement === "before" && icon}
      {children}
      {iconPlacement === "after" && icon}
    </>
  ) : (
    children
  );

  return props.href ? (
    <a {...props}>{childElements}</a>
  ) : (
    <button {...props}>{childElements}</button>
  );
}
