export const formatPrice = (price?: number) => {
  return price && `$${price.toLocaleString()}`;
};

export const formatDiscount = (
  percent?: number,
  havePrefix?: boolean,
  haveSuffix?: boolean,
) => {
  return (
    percent &&
    `${havePrefix ? "-" : ""}${percent}%${haveSuffix ? "\u00a0off" : ""}`
  );
};
