import { NewsTicker } from "@sphtech/dmg-design-system";
import { STNewsTickerResponse } from "@src/app/types/ServerCustomContext";
// import { SHOW_DEBUG_INFO } from "@src/app/constants";
import { ReactNode } from "react";
import useSWR from "swr";

const ST_NEWSTICKER_API = "/_plat/api/st_newsticker";

function StNewsticker(): ReactNode {
  const { data, error, isLoading } = useSWR<STNewsTickerResponse, unknown>(
    ST_NEWSTICKER_API,
  );

  return (
    !error &&
    // display empty grey ticker as placeholder while loading / SSR
    (isLoading || !data?.results || !!data.results.length) && (
      <NewsTicker
        // placeholder without any text
        sourceFrom={isLoading ? "" : "From The Straits Times |"}
        duration={80}
        layout="horizontal"
        items={data?.results || []}
      />
    )
  );
}

export default StNewsticker;
