import { ReactElement, ReactNode } from "react";
import styles from "./videogallerysummary.module.css";

type Props = {
  children: ReactNode;
};

type ReturnType = ({ children }: Props) => ReactElement;

function createVideoSummary(style: string): ReturnType {
  return ({ children }: Props): ReactElement => (
    <p className={`${style}`}>{children}</p>
  );
}

export const VideoSummarySmall = createVideoSummary(
  styles.videoGallerySummarySmall,
);
export const VideoSummary = createVideoSummary(styles.videoGallerySummary);
