import { Typography } from "@sphtech/dmg-design-system";
import { StoryData } from "@src/app/types/ServerCustomContext";
import { getSponsorName, isSponsoredStory } from "@src/app/utils/sponsored";
import { ReactNode, useEffect, useState } from "react";

const { Disclaimer } = Typography;

type SponsorshipProps = {
  sponsorship: StoryData["sponsorship"];
  typeName: string;
};

export default function Sponsorship({
  sponsorship,
  typeName,
}: SponsorshipProps): ReactNode {
  const [clientNow, setClientNow] = useState<Date>();
  useEffect(() => {
    setClientNow(new Date());
  }, []);

  if (isSponsoredStory(sponsorship, typeName, clientNow)) {
    const text = getSponsorName(sponsorship);
    return text && <Disclaimer big>{text}</Disclaimer>;
  }
}
