import { configSingleStory, configSite } from "@pub/config";
import { SectionData, StoryData } from "@src/app/types/ServerCustomContext";

import { MetaDataProps } from "./types";

const filterCanonicalUrl = (url: string = "") => {
  // if canonical URL belongs to same site, then no need to override.
  return url.startsWith(configSite.address) ? undefined : url;
};

export const mapStoryDataToMetaData = (data: StoryData): MetaDataProps => {
  const homeSection = data.sections.length && data.sections[0];
  return {
    type: "Article",
    title: data.seoFields.metaTitle || data.processedElements.headline,
    description:
      data.seoFields.metaDescription ||
      data.processedElements.standfirst ||
      data.processedElements.bodyElements.find((e) => e.type === "paragraph")
        ?.value,
    image: data.processedElements.coverImage?.crops.landscape,
    path: data.path,
    canonicalUrl: filterCanonicalUrl(data.seoFields.canonicalUrl),
    robots:
      data.seoFields.metaRobots === "1"
        ? "noindex, nofollow"
        : "index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1",
    ogFields: data.ogFields,
    storyData: data,
    breadcrumb: homeSection
      ? [homeSection.parent, homeSection]
          .filter((section): section is StoryData["sections"][0] => !!section)
          .map((section) => ({
            name: section.name,
            item: section.path,
          }))
      : undefined,
  };
};

export const mapSectionDataToMetaData = (data: SectionData): MetaDataProps => {
  // take the first story exludin the anchor block
  const firstStory =
    (data.topStories.length > 1 && data.topStories[1]) ||
    (!!data.listedStories.length && data.listedStories[0]);

  const title = data.tagSlug
    ? data.name
    : (data.listGroup?.activeTitle !== "All" && data.listGroup?.activeTitle) ||
      data.name;

  return {
    type: "CollectionPage",
    path: data.path,
    title: title,
    breadcrumb:
      data.listGroup?.activeTitle !== "All" && data.listGroup?.activeTitle
        ? [
            {
              name: data.name,
              item: data.path.split("/").slice(0, -1).join("/"),
            },
          ]
        : undefined,
    image: firstStory ? firstStory.image?.crops.landscape : undefined,
  };
};

export function getAuthors(
  storyData: StoryData,
): { text: string; href?: string }[] {
  if (storyData.displaySetting?.hideAuthorFlag) {
    return [];
  }
  if (storyData.byline) {
    return [{ text: storyData.byline }];
  }
  if (storyData.authors?.length) {
    return storyData.authors.map((author) => ({
      text: author.name,
      href: author.profile?.urlPath,
    }));
  }
  if (configSingleStory.byline?.defaultCredit) {
    return [
      {
        text: configSingleStory.byline.defaultCredit,
        href: "/about-us",
      },
    ];
  }
  return [];
}
