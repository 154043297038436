import { ReactElement } from "react";
import { useCarousel } from "../../../hooks";
import { videoGalleryItem } from "../../Block/VideoGallery/types";

import cx from "classnames";

import styles from "./titleScroll.module.css";
import {
  VideoGalleryLandscape,
  VideoGalleryPortraitSmall,
} from "../../Block/VideoGallery/VideoGallery";
import { SectionSubHeading } from "../../Block/SectionHeading/SectionHeading";
import Icon from "../../Element/Icon/icon";

type Props = {
  title?: string;
  items: Array<videoGalleryItem>;
  noOfItemsInView: number;
  leftIcon: ReactElement;
  rightIcon: ReactElement;
};

/**
 * TitleSideScroll
 * Description: Render items inside the carousel with a title and left and right arrow.
 */
function createTileSideScroll(type: "portrait" | "landscape") {
  const VideoItem =
    type === "landscape" ? VideoGalleryLandscape : VideoGalleryPortraitSmall;

  return ({
    title,
    items,
    leftIcon,
    rightIcon,
    noOfItemsInView,
  }: Props): ReactElement => {
    const {
      TouchHandlers,
      refPassthrough,
      next,
      prev,
      hasReachedEnd,
      hasSlideMoved,
    } = useCarousel(items.length, noOfItemsInView);

    return (
      <div className={styles.container}>
        {title !== undefined && (
          <SectionSubHeading
            title={title}
            arrows={
              type === "landscape" && (
                <>
                  <button
                    onClick={() => prev()}
                    style={{
                      visibility: hasSlideMoved ? "visible" : "hidden",
                    }}
                  >
                    <Icon>{leftIcon}</Icon>
                  </button>
                  <button
                    onClick={() => next()}
                    style={{
                      visibility: hasReachedEnd ? "hidden" : "visible",
                    }}
                  >
                    <Icon>{rightIcon}</Icon>
                  </button>
                </>
              )
            }
          />
        )}
        {type === "portrait" && (
          <button
            className={cx(styles.prev)}
            onClick={() => prev()}
            style={{
              display: hasSlideMoved ? "block" : "none",
            }}
          >
            <Icon>{leftIcon}</Icon>
          </button>
        )}
        <div
          className={styles.carouselItemsContainer}
          {...TouchHandlers}
          ref={refPassthrough}
        >
          <div
            className={cx(
              styles.item,
              type === "portrait" ? styles.itemPortrait : styles.itemLandScape,
              type === "portrait" &&
                items.length > 3 &&
                styles.itemPortraitMUpScroll,
            )}
          >
            {items.map(({ ...item }, index: number) => (
              <VideoItem item={item} key={index} />
            ))}
          </div>
        </div>
        {type === "portrait" && (
          <button
            className={cx(styles.next)}
            onClick={() => next()}
            style={{
              display: hasReachedEnd ? "none" : "block",
            }}
          >
            <Icon>{rightIcon}</Icon>
          </button>
        )}
      </div>
    );
  };
}

export const PortraitScroll = createTileSideScroll("portrait");
export const LandscapeScroll = createTileSideScroll("landscape");
