import { configSkimlinks } from "@pub/config";
import { loadScript } from "@sphtech/dmg-design-system";
import { useEffect } from "react";

export default configSkimlinks.active ? AnalyticsSkimlinks : () => null;

declare global {
  interface Window {
    Genius: {
      amazon: {
        addOnClickRedirect(a: string, b: boolean, c: string): void;
      };
    };
  }
}

function AnalyticsSkimlinks() {
  useEffect(() => {
    const geniuslinkFile = `//geniuslinkcdn.com/snippet.min.js`;
    const skimlinksFile = `https://s.skimresources.com/js/${configSkimlinks.skimlinkId}.skimlinks.js`;
    loadScript(geniuslinkFile, true).then(
      () => {
        window.Genius.amazon.addOnClickRedirect(
          configSkimlinks.geniuslinkId,
          true,
          "https://buy.geni.us",
        );
        void loadScript(skimlinksFile, true);
      },
      () => {},
    );
  }, []);
  return null;
}
