import { configHome, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  Ads,
  AnchorBlock,
  ContentBlock,
  LatestArticle,
  SectionContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataIndex?: number;
  alternateBg?: boolean;
};

/**
 * Description: Content Block 3 items with IMU | Content Block F (3 tiles with IMU)
 */
export default function HomeContentBlockF({
  data,
  dataIndex = 0,
  alternateBg,
}: Props): ReactNode {
  const ContentBlockThreeItemsWithImu = data.contentBlock.filter(
    (item) => item.type === "3items_imu",
  );

  return (
    !!ContentBlockThreeItemsWithImu[dataIndex]?.items.length && (
      <div
        className={cx(
          alternateBg && styles.backgroundFill,
          styles.gutter,
          styles.contentBlockFContainer,
        )}
      >
        <div
          className={cx(styles.bodyContainer, styles.contentBlockOverrideColor)}
        >
          <ContentBlock
            heading={ContentBlockThreeItemsWithImu[dataIndex].title}
            cta={{
              name: configHome.contentBlockF?.viewMore?.text || "VIEW ALL",
              url: ContentBlockThreeItemsWithImu[dataIndex].url,
              icon: configHome.viewMore?.icon,
            }}
            imu={<Ads.AdsSideBar />}
            withDivider={configHome.headingDivider}
            isHeadingClickable={configHome.isHeadingClickable}
          >
            <SectionContext.Provider
              value={{
                uniqueName: configHome.contentBlockF?.skipSectionContext
                  ? undefined
                  : ContentBlockThreeItemsWithImu[dataIndex].uniqSectionName,
              }}
            >
              <div className={styles.contentBlockThreeInnerContainer}>
                <div
                  className={cx(
                    styles.contentBlockThreeItemsContainer,
                    styles.latestTeaserFontOverride,
                  )}
                >
                  {ContentBlockThreeItemsWithImu[dataIndex].items.map(
                    (item, index) => {
                      const isItemLandscape = configHome.contentBlockF
                        ?.landscapeIndexes
                        ? configHome.contentBlockF.landscapeIndexes.includes(
                            index,
                          )
                        : index === 2;

                      const isItemLayoutTwoColumn =
                        configHome.contentBlockF?.layoutTwoColumn &&
                        configHome.contentBlockF.layoutTwoColumn.includes(
                          index,
                        );

                      const isItemLayoutOneColumn =
                        configHome.contentBlockF?.anchorBlock?.index === index;

                      const itemTeaserArticle =
                        isItemLayoutOneColumn &&
                        configHome.contentBlockF?.anchorBlock?.mediaScreen ===
                          "all-screen" ? (
                          <AnchorBlock
                            {...item}
                            loading="lazy"
                            placeholder={placeholders.landscape}
                            mobileCrop={configTeaser.anchorBlock?.mobileCrop}
                            hideVerticalLine={
                              configHome.contentBlockF.anchorBlock
                                .hideVerticalLine
                            }
                          />
                        ) : (
                          <LatestArticle
                            {...item}
                            mode={isItemLandscape ? "landscape" : "portrait"}
                            placeholders={placeholders}
                            landscapeImageWidth={
                              isItemLayoutTwoColumn
                                ? configHome.contentBlockF
                                    ?.twoColumnsLandscapeImageWidth || 315
                                : configHome.contentBlockF
                                    ?.landscapeImageWidth || 630
                            }
                            portraitImageWidth={
                              configHome.contentBlockF?.portraitImageWidth ||
                              315
                            }
                            displayPublishDate={configTeaser.publishDate}
                          />
                        );

                      return (
                        <div
                          key={index}
                          className={cx(
                            styles.contentBlockThreeItems,
                            isItemLandscape
                              ? isItemLayoutTwoColumn
                                ? styles.landscapeTwoColumn
                                : styles.landscape
                              : styles.portrait,
                            isItemLayoutOneColumn && styles.anchorBlock,
                          )}
                        >
                          {!isItemLayoutOneColumn ? (
                            <div className={styles.mDownGutterSides}>
                              {itemTeaserArticle}
                            </div>
                          ) : (
                            itemTeaserArticle
                          )}
                        </div>
                      );
                    },
                  )}
                </div>
              </div>
            </SectionContext.Provider>
          </ContentBlock>
        </div>
      </div>
    )
  );
}
