import React, { PropsWithChildren } from "react";
import { makeSvgDataSrc } from "../placeholder";
import "./ads.css";

const getDimensions = (unitId: string) => {
  switch (unitId) {
    case "leaderboard":
    case "midcontent":
      return { width: 728, height: 90 };
    default:
      return { width: 300, height: 250 };
  }
};

function createAdUnit(unitId: string): React.FC<PropsWithChildren> {
  if (import.meta.env.VITE_DISABLE_ADS === "true") {
    const dimensions = getDimensions(unitId);
    return ({ children }) => (
      <div data-placeholder={unitId}>
        <div className="sphm_ad">
          <img
            style={{ maxWidth: "100%" }}
            src={makeSvgDataSrc({
              ...dimensions,
              text: `Ad: ${unitId}`.toUpperCase(),
            })}
          />
        </div>
        {children}
      </div>
    );
  }

  // data-layout-position affects the size of ads displayed
  return ({ children }) => <div data-layout-position={unitId}>{children}</div>;
}

export const AdsLeaderboard = createAdUnit("leaderboard");

export const AdsMidContent = createAdUnit("midcontent");

export const AdsSideBar = createAdUnit("sidebar");

export const AdsSideBarDesktop = createAdUnit("sidebardesktop");

export const AdsSideBarMobile = createAdUnit("sidebarmobile");

export const AdsGallerylightbox = createAdUnit("gallerylightboximu");

export const AdsGallerylightboxItemImu = createAdUnit("gallerylightboxitem");

export const AdsGalleryItem = createAdUnit("galleryitem");

export const AdsGallery = createAdUnit("gallery");

export const EmbeddedGallery = createAdUnit("embeddedgallery");

export const AdsEmbeddedGalleryItems = createAdUnit("embededgalleryitems");

export const AdsMidArticle = createAdUnit("midarticle");
