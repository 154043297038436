import { configHome } from "@pub/config";
import { MagazineListing } from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
};

/**
 * Description: Magazine Listing
 */
export default function HomeMagazineListing({ data }: Props): ReactNode {
  return (
    data.magazineIssues.items.length !== 0 && (
      <div className={cx(styles.bodyContainer)}>
        <div className={styles.magListingOuterContainer}>
          <MagazineListing
            title={data.magazineIssues.title}
            subscriptionInfo={{
              subHeading: data.magazineIssues.subscriptionInfo?.heading || "",
              description:
                data.magazineIssues.subscriptionInfo?.description || "",
            }}
            subscriptionCta={{
              url: data.magazineIssues.subscriptionInfo?.buttonUrl || "",
              label:
                data.magazineIssues.subscriptionInfo?.buttonText || "Subscribe",
              icon: configHome.magazineListing?.ctaIcon,
              variant: configHome.magazineListing?.buttonVariant,
            }}
            carousel={{
              items: data.magazineIssues.items,
            }}
            midDivider={configHome.magazineListing?.midDivider}
            headingDivider={configHome.headingDivider}
          />
        </div>
      </div>
    )
  );
}
