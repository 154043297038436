import cx from "classnames";
import { createPortal } from "react-dom";
import { RoutePathsType } from "../../Utility/dataTypes";
import MegaMenu from "./MegaMenu";
import styles from "./megamenuportal.module.css";
import { SocialIconProps } from "../../Block/SocialIcon/SocialIcon";

type MegaMenuPropsType = React.ComponentProps<typeof MegaMenu>;

/**
 * Used to be part of Base layout.
 * Custom MegaMenu component for Base layout.
 */
const MegaMenuPortal: React.FC<{
  isOpen: boolean;
  toggleSidebar: () => void;
  SubscribeBoxProps?: MegaMenuPropsType["SubscribeBoxProps"];
  RoutePaths: RoutePathsType;
  socialIconProps?: SocialIconProps;
  megaMenuProps: Partial<MegaMenuPropsType>;
}> = ({
  isOpen,
  toggleSidebar,
  SubscribeBoxProps,
  RoutePaths,
  socialIconProps,
  megaMenuProps,
}) =>
  createPortal(
    <div
      className={cx(
        isOpen ? styles.overlay : styles.visiblityNone,
        styles.animation,
      )}
      role="button"
      aria-hidden="true"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          toggleSidebar();
        }
      }}
    >
      <MegaMenu
        isOpen={isOpen}
        toggleSidebar={toggleSidebar}
        FooterItemsProps={RoutePaths.Footer}
        MegaMenuItemsProps={RoutePaths.MegaMenu}
        SubscribeBoxProps={SubscribeBoxProps}
        MegaMenuType="doubleColumn"
        socialIconProps={socialIconProps}
        {...megaMenuProps}
      />
    </div>,
    document.body,
  );

export default MegaMenuPortal;
