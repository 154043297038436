import { ReactElement } from "react";

type Props = {
  height?: number;
  width?: number;
  direction?: "left" | "right" | "up" | "down";
  stroke?: string;
};

export default function Arrow({
  height = 54,
  width = 28,
  direction,
  stroke = "white",
}: Props): ReactElement {
  const transform = {
    left: "180deg",
    right: "0deg",
    up: "270deg",
    down: "90deg",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 54"
      fill="none"
      style={direction && { transform: `rotate(${transform[direction]})` }}
    >
      <path
        d="M1 53L26 27L0.999998 1"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
