import { StoryElementsApiResponse } from "@app/types/ServerCustomContext";
import { useInView } from "@components/hooks/hook";
import { useCallback, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";

import EmbeddedGalleryElement from "../EmbeddedGalleryElement/EmbeddedGalleryElement";

type Props = {
  id: string;
  path: string;
};

const EmbeddedGalleryLoader = ({ id, path }: Props) => {
  const { data } = useSWR<StoryElementsApiResponse>(
    `/_plat/api/story/elements_by_id?ids=${id}`,
  );

  if (!data?.results) return null;

  return <EmbeddedGalleryElement elements={data.results} id={id} path={path} />;
};

export const EmbeddedGalleryInView = ({ id, path }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [searchParams] = useSearchParams();
  const eGalleryId = searchParams.get("egallery");

  const { ref: refPassthrough, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const setRefs = useCallback(
    (node: HTMLDivElement | null) => {
      ref.current = node;
      refPassthrough(node);
    },
    [refPassthrough],
  );

  return (
    <div ref={setRefs}>
      {(inView || eGalleryId === id) && (
        <EmbeddedGalleryLoader id={id} path={path} />
      )}
    </div>
  );
};
