import { ReactNode } from "react";

export const intersperse = (
  list: ReactNode[],
  separator: (item: ReactNode, index: number) => ReactNode,
) => {
  if (list.length === 0) {
    return [];
  }

  return list.slice(1).reduce<ReactNode[]>(
    (items, item, i) => {
      return items.concat([separator(item, i), item]);
    },
    [list[0]],
  );
};

export const nl2br = (text: string) =>
  text.includes("\n")
    ? intersperse(text.split("\n"), (_, i) => <br key={i} />)
    : text;
