import { configHome, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  Ads,
  AnchorBlock,
  ContentBlock,
  LatestArticle,
  SectionContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataIndex?: number;
  alternateBg?: boolean;
};

/**
 * Description: Content Block 5 items with IMU | Content Block L (4 tiles with IMU)
 */
export default function HomeContentBlockL({
  data,
  dataIndex = 0,
  alternateBg,
}: Props): ReactNode {
  const ContentBlockFiveItemsWithIMU = data.contentBlock.filter(
    (item) => item.type === "contentBlockL",
  );

  return (
    !!ContentBlockFiveItemsWithIMU[dataIndex]?.items.length && (
      <div
        className={cx(
          alternateBg && styles.backgroundFill,
          alternateBg && styles.gutterTopBottom,
          styles.contentBlockLContainer,
        )}
      >
        <div
          className={cx(
            styles.bodyContainer,
            styles.contentBlockOverrideColor,
            styles.gutter,
          )}
        >
          <ContentBlock
            inlineGutter={false}
            heading={ContentBlockFiveItemsWithIMU[dataIndex].title}
            cta={{
              name: configHome.contentBlockL?.viewMore?.text || "VIEW ALL",
              url: ContentBlockFiveItemsWithIMU[dataIndex].url,
              icon: configHome.viewMore?.icon,
            }}
            imu={<Ads.AdsSideBar />}
            withDivider={configHome.headingDivider}
            isHeadingClickable={configHome.isHeadingClickable}
          >
            <SectionContext.Provider
              value={{
                uniqueName: configHome.contentBlockL?.skipSectionContext
                  ? undefined
                  : ContentBlockFiveItemsWithIMU[dataIndex].uniqSectionName,
              }}
            >
              <div className={styles.contentBlockThreeInnerContainer}>
                <div
                  className={cx(
                    styles.contentBlockLFiveItemsContainer,
                    styles.latestTeaserFontOverride,
                  )}
                >
                  {ContentBlockFiveItemsWithIMU[dataIndex].items.map(
                    (item, index) => {
                      const isItemLandscape = configHome.contentBlockL
                        ?.landscapeIndexes
                        ? configHome.contentBlockL.landscapeIndexes.includes(
                            index,
                          )
                        : index === 2;

                      const isItemLayoutOneColumn =
                        configHome.contentBlockL?.anchorBlock?.index === index;

                      const itemTeaserArticle =
                        isItemLayoutOneColumn &&
                        configHome.contentBlockL?.anchorBlock?.mediaScreen ===
                          "all-screen" ? (
                          <AnchorBlock
                            {...item}
                            loading="lazy"
                            placeholder={placeholders.landscape}
                            mobileCrop={configTeaser.anchorBlock?.mobileCrop}
                            hideVerticalLine={
                              configHome.contentBlockL.anchorBlock
                                .hideVerticalLine
                            }
                          />
                        ) : (
                          <LatestArticle
                            {...item}
                            mode={isItemLandscape ? "landscape" : "portrait"}
                            placeholders={placeholders}
                            landscapeImageWidth={
                              configHome.contentBlockL?.landscapeImageWidth ||
                              630
                            }
                            portraitImageWidth={
                              configHome.contentBlockL?.portraitImageWidth ||
                              315
                            }
                            displayPublishDate={configTeaser.publishDate}
                          />
                        );

                      return (
                        <div
                          key={index}
                          className={cx(
                            styles.contentBlockThreeItems,
                            isItemLandscape
                              ? styles.landscape
                              : styles.portrait,
                            isItemLayoutOneColumn && styles.anchorBlock,
                          )}
                        >
                          {!isItemLayoutOneColumn ? (
                            <div className={cx(styles.itemTeaserLContainer)}>
                              {itemTeaserArticle}
                            </div>
                          ) : (
                            itemTeaserArticle
                          )}
                        </div>
                      );
                    },
                  )}
                </div>
              </div>
            </SectionContext.Provider>
          </ContentBlock>
        </div>
      </div>
    )
  );
}
