import { configContentHub } from "@pub/config";
import { ResponsiveImage, Typography } from "@sphtech/dmg-design-system";
import { ContentHubData } from "@src/app/types/ServerCustomContext";
import cx from "classnames";
import { ReactElement } from "react";

import { Annotation } from "../../Article/StorylineElements/Annotation/Annotation";
import CustomHtml from "../../Article/StorylineElements/CustomHtml";
import StorylineImage from "../../Article/StorylineElements/Image";
import SubHeadingElement from "../../Article/StorylineElements/SubHeadingElement";
import styles from "../contenthub.module.css";

const { Heading, Body, Label } = Typography;

type ContentHubBodyContentProps = {
  data: {
    name: ContentHubData["name"];
    storyPostData?: ContentHubData["storyPostData"];
  };
};

export default function ContentHubBodyContent({
  data,
}: ContentHubBodyContentProps): ReactElement {
  return (
    <div className={cx(styles.contenthubBodyContent, styles.overlapContainer)}>
      {configContentHub.configBodyContent.withHeading && (
        <div className={styles.contenthubHeading}>
          <Heading.SectionTitle>{data.name}</Heading.SectionTitle>
        </div>
      )}

      {data.storyPostData && data.storyPostData.bodyElements && (
        <div className={styles.contenthubPostBody}>
          {data.storyPostData.bodyElements.map((element, index) => {
            if (element.type === "paragraph") {
              return (
                <Body key={index}>
                  <Annotation element={element} />
                </Body>
              );
            }
            if (element.type === "sub_head") {
              return <SubHeadingElement key={index} element={element} />;
            }
            if (element.type === "image") {
              return (
                <StorylineImage
                  key={index}
                  element={element}
                  bodyMaxWidth={1000}
                />
              );
            }
            if (element.type === "embed") {
              return <CustomHtml key={index} element={element} />;
            }
          })}
        </div>
      )}
      {/* Brought to you by will always at the bottom */}
      {data.storyPostData && data.storyPostData.broughtToYouBy && (
        <div className={styles.contenthubPostBodyColumnImageContainer}>
          {data.storyPostData.broughtToYouBy.map(
            ({ title, targetUrl, image }, index) => {
              return (
                <div
                  key={index}
                  className={styles.contenthubPostBodyColumnImage}
                >
                  <a href={targetUrl}>
                    <ResponsiveImage
                      alt={image?.alt || ""}
                      src={image?.src || ""}
                      srcHeight={image?.srcHeight || 0}
                      srcWidth={image?.srcWidth || 0}
                      displayWidth={320}
                    />
                  </a>

                  <div className={styles.columnImagInfo}>
                    <Label key={index}>{title}</Label>
                  </div>
                </div>
              );
            },
          )}
        </div>
      )}
    </div>
  );
}
