import { Helmet } from "react-helmet-async";
import newRelicAgent from "./scripts/nr-loader-spa-1.259.0.min.js?url";

const id = import.meta.env.VITE_NEW_RELIC_ID;
const key = import.meta.env.VITE_NEW_RELIC_KEY;

const isConfigured = !!(id && key);
if (!isConfigured) {
  console.warn("Skipping NewRelic import.meta.env", {
    VITE_NEW_RELIC_ID: id,
    VITE_NEW_RELIC_KEY: key,
  });
}

const NewRelic = isConfigured
  ? () => {
      return (
        <Helmet>
          <script>
            {[
              "window.NREUM||(NREUM={})",
              'NREUM.init={distributed_tracing:{enabled:true},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]}}',
              `NREUM.loader_config={accountID:"225023",trustKey:"225023",agentID:"${id}",licenseKey:"${key}",applicationID:"${id}"}`,
              `NREUM.info={beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:"${key}",applicationID:"${id}",sa:1}`,
            ].join(";")}
          </script>
          <script type="text/javascript" src={newRelicAgent} async />
        </Helmet>
      );
    }
  : () => null;

export default NewRelic;
