import cx from "classnames";
import { ReactElement, ReactNode } from "react";
import style from "./navlink.module.css";

export type Props = {
  children: ReactNode;
  href: string;
  target?: "_blank";
  customClassName?: string | string[];
};

/**
 *
 * Description: [Navgation Link used in the footer or navbar]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function NavLink({
  children,
  href,
  target,
  customClassName,
}: Props): ReactElement {
  return (
    <a
      className={cx(style.navlink, customClassName)}
      href={href}
      target={target}
    >
      {children}
    </a>
  );
}
