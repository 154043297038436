import { SHOW_DEBUG_INFO } from "../constants";

export class FetchError extends Error {
  constructor(
    message: string,
    public statusCode: number,
  ) {
    super(message);
    this.name = "FetchError";
  }
}

export const fetcher = async (url: string) => {
  const response = await fetch(url);
  if (!response.ok) {
    if (SHOW_DEBUG_INFO) {
      console.error("[api fetcher]", response.status, url);
    }
    throw new FetchError(response.statusText, response.status);
  }
  return response.json();
};
