import React from "react";
import { datetimeFormatter } from "./utils";

export type DateLabelProps = {
  date: Date;
  format?: string;
};

function DateTime({
  date,
  format = "dd mname yyyy",
}: DateLabelProps): React.ReactElement {
  return (
    <time dateTime={date.toISOString()} suppressHydrationWarning>
      {datetimeFormatter(date, format || "dd mname yyyy")}
    </time>
  );
}

export default DateTime;
