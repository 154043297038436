import { ResponseType } from "@sphtech/web2-core/ssr";

import ErrorLayout from "./ErrorLayout";

const ErrorElementLayout = () => {
  return (
    <ErrorLayout
      clientSideError
      response={{
        type: ResponseType.SERVER_ERROR,
        statusCode: 500,
      }}
    />
  );
};

export default ErrorElementLayout;
