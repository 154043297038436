import { StoryData } from "@app/types/ServerCustomContext";
import AdSettings from "@app/utils/AdSettings";
import MetaData from "@components/Modules/MetaData";
import { mapStoryDataToMetaData } from "@components/Modules/MetaData/mappers";
import PubBaseLayout from "@pub/BaseLayout";
import { NoSSR } from "@sphtech/web2-core/components";
import AnalyticsGrapeshot from "@src/app/utils/trackers/grapeshot";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import InfiniteScroll from "./InfiniteScroll";
import SinglePostLayout from "./SinglePostLayout";

export type ArticleLayoutProps = {
  storyData: StoryData;
};

/**
 * ArticleLayout
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function ArticleLayout(props: ArticleLayoutProps): ReactNode {
  const { storyData } = props;
  const { typeName, sections, processedElements } = storyData;
  const { headline } = processedElements;
  const location = useLocation();

  const [currentInViewStory, setCurrentInViewStory] = useState(storyData);
  const pathRef = useRef(storyData.path);
  useEffect(() => {
    const { path } = currentInViewStory;
    if (pathRef.current !== path) {
      pathRef.current = path;
      history.pushState({}, "", path);
      window.SPHMGTM.cmd.push(`pageview=${path}`);
    }
  }, [currentInViewStory]);
  const inViewList = useRef<StoryData[]>([]);

  const onInView = useCallback((storyData: StoryData, inView: boolean) => {
    const initiallyInView = inViewList.current[0];
    const inViewListIndex = inViewList.current.findIndex(
      (d) => d.path === storyData.path,
    );
    if (inView && inViewListIndex === -1) {
      inViewList.current.unshift(storyData);
    } else if (!inView && inViewListIndex !== -1) {
      inViewList.current.splice(inViewListIndex, 1);
    }
    const newInView = inViewList.current.length && inViewList.current[0];
    if (newInView && initiallyInView !== newInView) {
      setCurrentInViewStory(newInView);
    }
  }, []);

  const showLeaderboard = typeName !== "FeatureStory";

  return (
    <PubBaseLayout
      ArticleTitle={currentInViewStory.processedElements.headline}
      leaderboard={showLeaderboard}
    >
      <MetaData {...mapStoryDataToMetaData(currentInViewStory)} />
      {!!storyData.grapeshots && (
        <AnalyticsGrapeshot grapeshots={storyData.grapeshots} />
      )}
      <AdSettings
        StoryData={storyData}
        pageTitle={headline || ""}
        pageType="article"
        sectionName={sections[0]?.name}
        currentLocation={location}
        adUnits="lb1,imu1,imu2,imu3,midarticlespecial,midarticlespecial2,catfish,prestitial"
      />
      <SinglePostLayout
        storyData={storyData}
        onInView={onInView}
        hideContent={false}
      />
      {typeName !== "FeatureStory" && (
        <NoSSR>
          <InfiniteScroll storyData={storyData} onInView={onInView} />
        </NoSSR>
      )}
    </PubBaseLayout>
  );
}
