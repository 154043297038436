import { ReactElement } from "react";
import styles from "./divider.module.css"; // Import the CSS module

type DividerProps = {
  size?: "story" | "sm";
  variant?: "story" | "secondary";
};

import classNames from "classnames";
import { useCustomClassContext } from "../../../hooks/useCustomClassContext/CustomClassContext";

export default function Divider(props: DividerProps): ReactElement {
  const { size, variant } = props;

  const customClass = useCustomClassContext();

  const dividerClasses = classNames(
    styles.divider,
    size && styles[size],
    variant && styles[variant],
    variant === "secondary" && customClass?.divider?.secondary,
  );

  return <hr className={dividerClasses} />;
}
