import React, { ReactElement } from "react";
import styles from "./disclaimer.module.css"; // Import the CSS module

export type DisclaimerProps = {
  Element?: "div" | "span";
  children?: React.ReactNode;
  big?: boolean;
};

export default function Disclaimer({
  children,
  Element = `div`,
  big = false,
}: DisclaimerProps): ReactElement {
  const className = big ? styles.bigDisclaimer : styles.disclaimer;
  return <Element className={className}>{children}</Element>;
}
