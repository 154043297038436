import cx from "classnames";
import { ReactNode, useRef } from "react";
import Arrow from "../../Element/Arrow/Arrow";
import styles from "./listgroup.module.css";
import useListGroup from "./useListGroup";

type ListGroupProps = {
  items: {
    text: string;
    url: string;
  }[];
  /* This highlights the activeTitle from the list */
  activeTitle?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

/**
 * This is our list group component. It aims to display a series of content.
 */
function ListGroup({ items, activeTitle, onClick }: ListGroupProps): ReactNode {
  const listGroupRef = useRef<HTMLDivElement>(null);
  const { hasPrev, hasNext } = useListGroup({ listGroupRef, activeTitle });

  const handleClick = (direction: "left" | "right") => {
    if (!listGroupRef.current) return;
    const directionValue = direction === "left" ? -1 : 1;
    listGroupRef.current.scrollTo({
      left:
        listGroupRef.current.scrollLeft +
        listGroupRef.current.clientWidth * directionValue,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles.listGroupWithButtons}>
      <button
        className={cx(styles.arrowButton, !hasPrev && styles.hidden)}
        onClick={() => handleClick("left")}
      >
        <Arrow size={22} direction="left" />
      </button>

      <div
        ref={listGroupRef}
        className={styles.listGroup}
        data-layout-position="subnavbar"
      >
        {items.map(({ text, url }, index) => (
          <a
            href={url}
            onClick={onClick}
            className={cx(
              styles.listGroupItem,
              activeTitle === text && styles.itemSelected,
            )}
            key={index}
          >
            {text}
          </a>
        ))}
      </div>

      <button
        className={cx(styles.arrowButton, !hasNext && styles.hidden)}
        onClick={() => handleClick("right")}
      >
        <Arrow size={22} />
      </button>
    </div>
  );
}

export default ListGroup;
