// icon.tsx
import { ReactElement, ReactNode } from "react";

export type IconProps = {
  children: ReactNode;
};

export default function Icon({ children }: IconProps): ReactElement {
  return <i>{children}</i>;
}
