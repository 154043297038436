import { Fragment } from "react";
import Icon from "../../Element/Icon/icon";
import styles from "./embeddedshare.module.css";
import {
  Facebook,
  X,
  Whatsapp,
  Pinterest,
} from "../../Utility/SocialIcons/iconCricle";
import cx from "classnames";

const icons = [
  {
    icon: <Facebook width="36" height="36" />,
    name: "Facebook",
    baseUrl: "https://www.facebook.com/sharer/sharer.php?u=",
  },
  {
    icon: <X width="36" height="36" />,
    name: "X",
    baseUrl: "https://x.com/intent/tweet?url=",
  },
  {
    icon: <Whatsapp width="36" height="36" />,
    name: "Whatsapp",
    baseUrl: "https://api.whatsapp.com/send?text=",
  },
  {
    icon: <Pinterest width="36" height="36" />,
    name: "Pinterest",
    baseUrl: "http://pinterest.com/pin/create/button/?url=",
  },
];

type Props = {
  open: boolean;
  url: string;
  media?: string[];
};

export default function EmbeddedShare({
  url,
  open,
  media = ["Facebook", "X", "Whatsapp"],
}: Props) {
  return (
    <Fragment>
      {icons
        .filter(({ name }) => media.includes(name))
        .map(({ icon, baseUrl }, index) => (
          <div
            key={index}
            className={cx(styles.floatCricle, open && styles.floatCricleOpen)}
          >
            <a
              href={`${baseUrl}${encodeURIComponent(url)}`}
              target="_blank"
              rel="noopener"
              data-layout-position="socialshareicon"
            >
              <Icon>{icon}</Icon>
            </a>
          </div>
        ))}
    </Fragment>
  );
}
