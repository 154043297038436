import { TeaserDataApiResponse } from "@app/types/ServerCustomContext";
import { GA4_REF_CODE_EXPLORE_OTHER_TOPIC } from "@app/utils/ga/ref-code";
import { createTeaserMapper } from "@app/utils/mapTeaserDataToProps";
import { configTrackingCode } from "@pub/config";
import placeholders from "@pub/placeholders";
import { ExploreMore } from "@sphtech/dmg-design-system";
import { ReactNode } from "react";
import useSWR from "swr";

export type AppExploreMoreProps = {
  // Define the type for the props required by the component (if any)
  uniqSectionName: string;
  ignoreIds?: string;
  searchAfter?: string;
  render?: (children: ReactNode) => ReactNode;
  headingText?: string;
};

/**
 * AppExploreMore
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function AppExploreMore({
  uniqSectionName,
  ignoreIds,
  searchAfter,
  render = (children) => children,
  headingText = "EXPLORE OTHER TOPICS",
}: AppExploreMoreProps): ReactNode {
  const { data } = useSWR<TeaserDataApiResponse>(
    `/_plat/api/teaser/explore_more?uniqSectionName=${encodeURIComponent(uniqSectionName)}${
      searchAfter ? `&after=${encodeURIComponent(searchAfter)}` : ""
    }${ignoreIds ? `&ignoreIds=${encodeURIComponent(ignoreIds)}` : ""}`,
  );

  const exploreMoreData =
    !!data?.results?.length &&
    data.results.map(
      createTeaserMapper({
        gaRefCode:
          configTrackingCode.exploremore || GA4_REF_CODE_EXPLORE_OTHER_TOPIC,
      }),
    );

  return (
    exploreMoreData &&
    render(
      <ExploreMore
        headingText={headingText}
        items={exploreMoreData}
        placeholder={placeholders.landscape}
      />,
    )
  );
}
