import cx from "classnames";

import styles from "./table.module.css";

type TableBodyProps = {
  bodyData: string[][];
  hasColHeader: boolean | undefined;
};

const TableBody = ({ bodyData, hasColHeader }: TableBodyProps) => {
  return (
    <tbody>
      {bodyData.map((row, rowIndex) => (
        <tr key={rowIndex} className={styles.tableBodyRow}>
          {row.map((cell, colIndex) => {
            // Using th tag for column headings
            const TableHTMLTag = hasColHeader && colIndex === 0 ? "th" : "td";

            return (
              <TableHTMLTag
                key={colIndex}
                className={cx(
                  styles.cellStyles,
                  TableHTMLTag === "th" && styles.fontBold,
                )}
              >
                {cell}
              </TableHTMLTag>
            );
          })}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
