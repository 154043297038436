import { RelatedArticle } from "@sphtech/dmg-design-system";
import { TeaserDataApiResponse } from "@src/app/types/ServerCustomContext";
import { GA4_REF_CODE_RELATED } from "@src/app/utils/ga/ref-code";
import { buildUrlWithGARefCode } from "@src/app/utils/ga/tracking";
import useSWR from "swr";

type RelatedArticleInlineProps = {
  ids: string[];
};
function RelatedArticleInline({ ids }: RelatedArticleInlineProps) {
  const { data } = useSWR<TeaserDataApiResponse>(
    `/_plat/api/teaser/by_id?ids=${ids.join(",")}`,
  );

  if (!data) return null;

  const stories = data.results
    ?.map((ele) => {
      const { id, title, path, image } = ele;
      return {
        id: id || "",
        title: title,
        url: buildUrlWithGARefCode(path, GA4_REF_CODE_RELATED),
        image: image?.crops.landscape,
      };
    })
    //sort based on the order of ids
    .sort((a, b) => {
      return ids.indexOf(a.id) - ids.indexOf(b.id);
    });
  if (!stories) {
    return null;
  }
  return <RelatedArticle stories={stories} displayImage={true} />;
}

export default RelatedArticleInline;
