import { ResolvedFieldValue } from "@app/layouts/Article/elements";
import { Annotation } from "@app/layouts/Article/StorylineElements/Annotation/Annotation";
import { Typography } from "@sphtech/dmg-design-system";

import storylineStyles from "../../Storyline.module.css";
import styles from "./EmbeddedGalleryElement.module.css";

type EmbeddedGalleryTitleProps = {
  title?: ResolvedFieldValue;
  targetUrl?: ResolvedFieldValue;
};

const EmbeddedGalleryTitle = ({
  title,
  targetUrl,
}: EmbeddedGalleryTitleProps) => {
  if (!targetUrl) {
    return (
      <div className={styles.embeddedGalleryHeading}>
        <Typography.Heading.SubHeading2>
          {title && <Annotation element={title} />}
        </Typography.Heading.SubHeading2>
      </div>
    );
  }

  return (
    <div className={styles.embeddedGalleryHeading}>
      <Typography.Heading.SubHeading2>
        {title &&
          (targetUrl.value ? (
            <a
              href={targetUrl.value}
              className={storylineStyles.externalLinks}
              rel="noopener nofollow noreferrer"
              target="_blank"
            >
              <Annotation element={title} />
            </a>
          ) : (
            <Annotation element={title} />
          ))}
      </Typography.Heading.SubHeading2>
    </div>
  );
};

export default EmbeddedGalleryTitle;
