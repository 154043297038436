import { PromoBox, Typography } from "@sphtech/dmg-design-system";
import { ReactNode } from "react";

import { ResolvedCUEElement, ResolvedPromoBoxInfoElement } from "../elements";
import { Annotation } from "./Annotation/Annotation";

type PromoBoxElementProps = {
  element: ResolvedCUEElement;
};

export default function PromoBoxElement({
  element,
}: PromoBoxElementProps): ReactNode {
  const promoBoxInfo = element.children?.find(
    (el) => el.type === "promo_box_info",
  )?.additionalFields as ResolvedPromoBoxInfoElement["additionalFields"];

  return (
    <PromoBox
      label={promoBoxInfo.promoBoxLabelName?.value}
      title={promoBoxInfo.promoBoxHeading?.value}
    >
      {element.children
        ?.filter((el) => el.type === "paragraph")
        .map((element, index) => (
          <Typography.Body key={index}>
            <Annotation element={element} />
          </Typography.Body>
        ))}
    </PromoBox>
  );
}
