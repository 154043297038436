import { ReactNode } from "react";
//import our main package
import * as Typography from "../../Element/Typography";
//import our css
import styles from "./loader.module.css";

const { Heading } = Typography;

type LoaderProps = {
  label: string;
};

/**
 * This is loader component
 */
function Loader({ label }: LoaderProps): ReactNode {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.headingContainer}>
        <Heading.SubHeading4>{label}</Heading.SubHeading4>
      </div>
      <div className={styles.loader}>
        <div className={styles.loaderBar}></div>
      </div>
    </div>
  );
}

export default Loader;
