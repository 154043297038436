import * as Typography from "../../Element/Typography";
import Button from "../../Element/Button";

import { ReactNode } from "react";
import styles from "./affiliatesproduct.module.css";
import ResponsiveImage from "../../Element/ResponsiveImage";

type AffiliatesItemProduct = {
  title: string;
  discountPriceLabel?: string;
  discountPrice?: string;
  originalPriceLabel?: string;
  originalPrice?: string;
  discountInPercentage?: string;
  link: string;
  dynamicPricing?: string;
  image: {
    src: string;
    srcWidth: number;
    srcHeight: number;
    alt: string;
  };
};

type AffiliatesProductCTA = {
  label: string;
  target?: string;
  buttonIcon: ReactNode;
};

type AffiliatesProductProps = {
  item: AffiliatesItemProduct;
  itemWidth: number;
  itemHeight: number;
  cta?: AffiliatesProductCTA;
  enforceDoublePriceLine?: boolean;
};

const { Pagination } = Typography;
const { SubHeading4 } = Typography.Heading;

/**
 * AffiliatesProduct
 * Description:
 */
export default function AffiliatesProduct({
  item,
  itemWidth,
  cta,
  enforceDoublePriceLine,
}: AffiliatesProductProps): ReactNode {
  const isPriceDisplay =
    item.discountPrice !== undefined || item.originalPrice !== undefined;

  return (
    <div
      className={styles.container}
      style={{
        width: itemWidth,
        // Needed for NaturalCarousel items
        minWidth: itemWidth,
      }}
      data-layout-position="affiliateproduct"
    >
      {item.discountInPercentage && item.discountPrice && (
        <div className={styles.floatingContainer}>
          <span className={styles.floatingPercentageLabel}>
            <Pagination size="small">{item.discountInPercentage}</Pagination>
          </span>
        </div>
      )}

      <a
        href={item.link}
        className={styles.linkContainer}
        target="_blank"
        rel="noopener nofollow"
      >
        <span className={styles.imageContainer}>
          <ResponsiveImage
            {...item.image}
            fill="contain"
            fillAspectRatio={1}
            displayWidth={180}
            loading={import.meta.env.VITE_SKIP_LAZY ? undefined : "lazy"}
          />
        </span>
        <span
          className={styles.titleContainer}
          data-layout-position="affiliateproductname"
        >
          <SubHeading4>{item.title}</SubHeading4>
        </span>
      </a>

      {isPriceDisplay !== false && (
        <div className={styles.pricingContainer}>
          <div className={styles.currentPrice}>
            {item.discountPrice
              ? `${item.discountPriceLabel} ${item.discountPrice}`
              : `${item.originalPrice}`}
          </div>

          {item.discountPrice ? (
            <div className={styles.originalPrice}>
              {item.originalPriceLabel}
              <s className={styles.originalPriceStrikethrough}>
                {item.originalPrice}
              </s>
            </div>
          ) : enforceDoublePriceLine ? (
            <div className={styles.originalPrice}>&nbsp;</div>
          ) : null}
        </div>
      )}
      {cta && (
        <Button
          href={item.link}
          variant="secondary"
          icon={cta.buttonIcon}
          iconPlacement="before"
          target={cta.target ?? "_blank"}
          rel="noopener nofollow"
        >
          {cta.label}
        </Button>
      )}
    </div>
  );
}
