import { ProductContent } from "@sphtech/dmg-design-system";
import { ResolvedCUEElement } from "@src/app/layouts/Article/elements";
import { Annotation } from "@src/app/layouts/Article/StorylineElements/Annotation/Annotation";
import amazonPricingDataLoader from "@src/app/utils/amazonPricingDataloader";
import { formatPrice } from "@src/app/utils/product";
import useSWR from "swr";

import styles from "./product.module.css";
import { productDataDataLoader } from "./ProductIndex";
type Props = {
  element: ResolvedCUEElement;
};
export default function ProductContentElement(element: Props) {
  const { data, isLoading } = useSWR(element.element.relation?.id, (id) =>
    productDataDataLoader.load(id),
  );

  const asins = data?.dealsSource
    .map((deal) => deal.dynamicPricing)
    .filter(Boolean);

  const { data: extractedAmazonData } = useSWR(
    asins?.length ? asins : null,
    (keys) => {
      return amazonPricingDataLoader
        .loadMany(keys)
        .then((resolvedData) =>
          resolvedData.map((data) => (data instanceof Error ? null : data)),
        );
    },
  );

  return (
    !isLoading &&
    data && (
      <div className={styles.detailsContainer}>
        <ProductContent
          {...data}
          dealsSource={data.dealsSource.map((deal) => ({
            ...deal,
            discountPrice:
              (extractedAmazonData &&
                formatPrice(
                  extractedAmazonData.find(
                    (amazonDatum) =>
                      amazonDatum && amazonDatum.asin === deal.dynamicPricing,
                  )?.discountPrice,
                )) ||
              deal.discountPrice,
          }))}
          pros={data.pros?.map((content, index) => (
            <Annotation key={index} element={content} />
          ))}
          cons={data.cons?.map((content, index) => (
            <Annotation key={index} element={content} />
          ))}
          detailed={true}
        />
      </div>
    )
  );
}
