import {
  ConfigBrightcove,
  ConfigGAM,
  ConfigLotame,
  ConfigPermutive,
} from "@pub/config";

export const LOTAME_CONFIG: Omit<ConfigLotame, "active"> = {
  clientId: "12374",
};

export const PREMUTIVE_CONFIG: Omit<ConfigPermutive, "active"> = {
  organisationId: "5f876161-9740-4cc8-9b64-4585990b2690",
  workspaceId: "5f876161-9740-4cc8-9b64-4585990b2690",
  workspaceKey: "ab403253-b305-47fa-a31b-3efb2473166f",
};

export const BRIGHTCOVE_CONFIG: ConfigBrightcove = {
  accountid: "4802324438001",
};

export const DEFAULT_CONFIG_GAM: Omit<ConfigGAM, "account"> = {
  prestitialLimit: 3,
  prestitialFrequency: 1,
  catfishFrequency: 4,
  paragraphInsertPos: 3,
  paragraphInsertMax: 3,
  galleryInsertPos: 2,
  galleryInsertMax: 3,
};

export const LOAD_MORE_TEXT = "Loading more articles";
