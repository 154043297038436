import { ProductApiResponse } from "@src/app/types/ServerCustomContext";
import { isProductItem } from "@src/app/utils/extractProduct";
import { fetcher } from "@src/app/utils/swr";
import DataLoader from "dataloader";

const productDataDataLoader = new DataLoader(
  async (keys) => {
    const response = (await fetcher(
      `/_plat/api/product?id=${keys.join(",")}`,
    )) as ProductApiResponse;

    if (!response?.results) {
      return keys.map(() => null);
    }

    return keys.map((key) =>
      response.results?.find(
        (result) => result.id === key && isProductItem(result),
      ),
    );
  },
  { maxBatchSize: 10 },
);

export default productDataDataLoader;
