import { ReactNode } from "react";

import styles from "./videogallery.module.css";
import cx from "classnames";

import { videoGalleryItem } from "./types";
import { Image } from "../../Utility";
import ResponsiveIframe from "../../Element/ResponsiveIframe";
import { VideoSummary, VideoSummarySmall } from "../../Element/Typography";

type Props = {
  item: videoGalleryItem;
  beforeTitle?: ReactNode;
  afterTitle?: ReactNode;
  className?: string;
};

type VidoeGalleryReturnType = ({
  item,
  beforeTitle,
  afterTitle,
}: Props) => ReactNode;

/**
 *
 * Description: This componet is used when rendering a videoGallery. The video render in an iframe when the clicks the play button the iframe will be displayed.
 *
 */
function createVideoGallery(
  type: "portrait" | "landscape",
  size?: "small" | "large",
): VidoeGalleryReturnType {
  const Styling = cx(
    type === "portrait" && styles.itemPortrait,
    type === "landscape" && styles.itemLandScape,
  );

  return ({ item, beforeTitle, afterTitle }: Props): ReactNode => {
    const Summary = size === "small" ? VideoSummarySmall : VideoSummary;

    const target = item.captionUrl?.startsWith("http") ? "_blank" : undefined;
    const imageElement = item.image && (
      <Image
        src={item.image.src}
        alt={item.image.alt}
        width={item.image.srcWidth}
        height={item.image.srcHeight}
      />
    );

    return (
      <div className={styles.container}>
        <div className={styles.videoWrapper}>
          {item.videoUrl && (
            <ResponsiveIframe src={item.videoUrl} loading="lazy" />
          )}
          {imageElement &&
            (item.captionUrl ? (
              <a href={item.captionUrl} target={target}>
                {imageElement}
              </a>
            ) : (
              imageElement
            ))}
        </div>
        {/* if image show the summary, if video url don't the captions */}
        {type === "landscape" && !item.videoUrl && (
          <div className={Styling}>
            {beforeTitle}
            {item.captionUrl ? (
              <a
                href={item.captionUrl}
                className={styles.lineClamp}
                target={target}
              >
                <Summary>{item.caption}</Summary>
              </a>
            ) : (
              <div className={styles.lineClamp}>
                <Summary>{item.caption}</Summary>
              </div>
            )}
            {afterTitle}
          </div>
        )}
      </div>
    );
  };
}

export const VideoGalleryPortrait = createVideoGallery("portrait", "large");
export const VideoGalleryLandscape = createVideoGallery("landscape", "large");
export const VideoGalleryLandscapeSmall = createVideoGallery(
  "landscape",
  "small",
);
export const VideoGalleryPortraitSmall = createVideoGallery(
  "portrait",
  "small",
);
