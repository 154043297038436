import DataLoader from "dataloader";

import { AffiliatesApiResponse } from "../types/ServerCustomContext";
import {
  extractAmazonDynmaicPricing,
  isAmazonASINItem,
} from "./extractDynamicPricing";
import { fetcher } from "./swr";

const amazonPricingDataLoader = new DataLoader(
  async (keys) => {
    const response = (await fetcher(
      `/_plat/api/affiliates?asin=${keys.join(",")}`,
    )) as AffiliatesApiResponse;

    return keys.map((key) => {
      const matchedItems = response?.results?.find(
        (item) => item.ASIN === key && isAmazonASINItem(item),
      );
      return matchedItems && extractAmazonDynmaicPricing(matchedItems);
    });
  },
  { maxBatchSize: 10 },
);

export default amazonPricingDataLoader;
