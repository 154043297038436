import { useEffect } from "react";

/**
 * Copied over from app/components/hooks/hook.tsx
 * Used by Base layout
 */
export default function useBodyScrollLock(isOpen: boolean): void {
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const originalStyle = window.getComputedStyle(document.body).overflow;

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [isOpen]);
}
