/**
 * GA4 Tracking Codes
 */

//For homepage/listing pages
export const GA4_REF_CODE_ANCHORBLOCK_A = "anchorblocka";
export const GA4_REF_CODE_LATEST_BLOCKS = "latestblocks";
export const GA4_REF_CODE_TRENDING = "trending";
export const GA4_REF_CODE_CONTENTBLOCK_F = "contentblockf";
export const GA4_REF_CODE_SHOPPING_WIDGET = "shoppingwidget";
export const GA4_REF_CODE_CONTENTBLOCK = "contentblock";
export const GA4_REF_CODE_CONTENTBLOCK_G = "contentblockg";
export const GA4_REF_CODE_CONTENTBLOCK_B = "contentblockb";
export const GA4_REF_CODE_CONTENTBLOCK_A = "contentblocka";
//For category
export const GA4_REF_CODE_ANCHORBLOCK_B = "anchorblockb";
export const GA4_REF_CODE_TITLE_STACKED = "titlestacked";
export const GA4_REF_CODE_CONTENTBLOCK_L = "contentblockl";
export const GA4_REF_CODE_EXPLORE_OTHER_TOPIC = "explore";
export const GA4_REF_CODE_VIDEOGALLERY = "videogallery";
//For video
export const GA4_REF_CODE_CONTENTBLOCK_H = "contentblockh";
//For affiliate products/editor's picks
export const GA4_REF_CODE_AP_WIDGET = "apwidget";
export const GA4_REF_CODE_RELATED = "related";
export const GA4_REF_CODE_CONTENTBLOCK_C = "contentblockc";
export const GA4_REF_CODE_CONTENTBLOCK_J = "contentblockj";
export const GA4_REF_CODE_CONTENTBLOCK_D = "contentblockd";
//For contenthub
export const GA5_REF_CODE_CONTENTHUB = "contenthub";
export const GA5_REF_CODE_CONTENTHUB_A = "contenthuba";
export const GA5_REF_CODE_CONTENTHUB_B = "contenthubb";
