import { Image } from "../../Utility/index";
import { ImageProps } from "../../Utility/types";

import styles from "./logo.module.css";

type Props = ImageProps & {
  href: string;
};

export default function Logo({
  src,
  alt,
  width,
  height,
  href,
  loading,
}: Props) {
  return (
    <a href={href} className={styles.link}>
      <Image
        src={src}
        alt={alt}
        width={width}
        height={height}
        objectFit="contain"
        loading={import.meta.env.VITE_SKIP_LAZY ? undefined : loading}
      />
    </a>
  );
}
