import {
  PageTypesData,
  articleTypes,
  sph_data,
  sphmBaseData,
} from "./constants";

export type GAMProps = {
  currentLocation: { pathname: string };
  sectionName: string;
  pageType: string;
  pageTitle: string;
  adUnits: string;
  StoryData?: {
    id?: string;
    published?: string;
    updated?: string;
    authors?: { name: string }[];
    tags?: { id?: string; name: string }[];
    typeName?: string;
    urlPath?: string;
    sections?: { name: string; path: string }[];
    sponsorship: {
      sponsorCheckbox?: boolean;
      sponsorDateStart?: string;
      sponsorDateEnd?: string;
    };
  };
  siteConfig?: {
    siteName?: string;
    siteAddress?: string;
    gtmContainer?: string;
    adAccount?: string;
    adSection?: string;
  };
};

export default function Gam({
  sectionName,
  pageType,
  pageTitle,
  adUnits,
  StoryData,
  currentLocation,
  siteConfig,
}: GAMProps) {
  const PageTypes = [
    "category",
    "homepage",
    "search",
    "videos",
    "tag",
    "latest",
    "contenthub",
    "author",
  ]; // add more page types here
  let mappedAdUnits = "";

  const ProcessGam = () => {
    let baseData = sphmBaseData;
    let sphmArticleId = "";

    const site_name = siteConfig?.siteName as string;
    const site_address = siteConfig?.siteAddress as string;
    const gtm_container = siteConfig?.gtmContainer as string;
    const ad_account = siteConfig?.adAccount as string;

    const MappedPageData =
      PageTypesData[pageType as keyof typeof PageTypesData];

    const currentSectionName =
      pageType === "category"
        ? currentLocation.pathname.replace(/(^\/+|\/+$)/g, "")
        : MappedPageData?.section_name || "";

    if (StoryData && typeof StoryData == "object") {
      //i.e article page
      const {
        id,
        published,
        authors,
        tags,
        typeName,
        sections,
        sponsorship,
        updated,
      } = StoryData;

      //for articles, content level to be based on StoryData.sections[0].path
      const story_section =
        sections && sections.length > 0 ? sections[0].path : "";
      const [content_level_1, content_level_2, content_level_3] = story_section
        .split("/")
        // trims leading and trailing slashes
        .filter(Boolean) || ["", ""];

      const ad_section = [content_level_1, content_level_2, content_level_3]
        .filter((n) => n)
        .join("/");

      const articleId = id || "";
      sphmArticleId = articleId;

      mappedAdUnits = /^\w+(,\w+)*$/.test(adUnits) ? adUnits : "";

      const sponsored = !!sponsorship.sponsorCheckbox;
      const sponsor_start =
        (sponsorship.sponsorCheckbox && sponsorship.sponsorDateStart) || "";
      const sponsor_end =
        (sponsorship.sponsorCheckbox && sponsorship.sponsorDateEnd) || "";

      baseData = {
        ...baseData,
        articleId,
        content_level_1,
        content_level_2,
        content_level_3,
        ad_section,
        site_name,
        site_address,
        gtm_container,
        ad_account,
        article_type: articleTypes[typeName as keyof typeof articleTypes] || "",
        article_keyword: (tags && tags.map((a) => a.name).join(",")) || "",
        page_name: pageTitle || "",
        content_type: MappedPageData.content_type || "",
        content_publication_date: published?.split("T").shift() || "",
        content_publication_utc: published || "",
        content_last_updated_utc: updated || "",
        content_author: (authors && authors.map((a) => a.name).join(",")) || "",
        cue_articleid: articleId,
        sponsored,
        sponsor_start,
        sponsor_end,
      };
    }

    if (PageTypes.includes(pageType)) {
      let [content_level_1, content_level_2, content_level_3] = ["", "", ""];
      if (pageType === "category") {
        [content_level_1, content_level_2, content_level_3] =
          currentSectionName.split("/");
      }
      if (["tag", "search", "videos"].includes(pageType)) {
        content_level_1 = currentSectionName;
      }
      //content hub special case
      if (pageType === "contenthub") {
        content_level_1 = "topics";
      }

      const ad_section =
        [content_level_1, content_level_2, content_level_3]
          .filter((n) => n)
          .join("/") || MappedPageData?.section_name;

      mappedAdUnits = MappedPageData.adUnit || "";

      baseData = {
        ...baseData,
        page_name: sectionName || MappedPageData.page_name || "",
        content_level_1,
        content_level_2,
        content_level_3,
        ad_section,
        site_name,
        site_address,
        gtm_container,
        ad_account,
        article_type: pageType === "contenthub" ? "Content Hub" : "",
        article_keyword: pageType === "tag" ? sectionName || "" : "",
        content_type: MappedPageData.content_type || "",
      };
    }

    return {
      articleId: sphmArticleId,
      section: currentSectionName,
      baseData,
      Adunits: mappedAdUnits,
      contentType: MappedPageData.content_type,
    };
  };

  const { baseData, Adunits, section, contentType, articleId } = ProcessGam();

  return {
    baseData,
    sph_data,
    Adunits,
    section,
    contentType,
    articleId,
  };
}
