/**
 * Company: SPHMedia
 * Description: Responsible for rendering of bulleted list or numbered list
 */

import { ReactElement } from "react";

import { ResolvedCUEElement } from "../elements";
import styles from "../Storyline.module.css";
import { Annotation } from "./Annotation/Annotation";

type ListProps = {
  element: ResolvedCUEElement;
};

/**
 * List
 * Description: Render element for List
 * @param props - The props required by the component
 * @returns The rendered React element for List
 */
export default function List({ element }: ListProps): ReactElement {
  const ListElement = element.type === "list_numbered" ? "ol" : "ul";
  const items = element.children;

  return (
    <ListElement className={styles.listElement}>
      {!!items &&
        items.map((item, index) => {
          return (
            <li key={index} className={styles.listItem}>
              <Annotation element={item} />
            </li>
          );
        })}
    </ListElement>
  );
}
