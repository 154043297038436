import { configHome, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  AnchorBlock,
  ContentBlock,
  LatestArticle,
  SectionContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactElement } from "react";

import styles from "../homelayout.module.css";
import { HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  alternateBg?: boolean;
  dataIndex?: number;
  trendingBlock?: boolean;
};

/**
 * Description: ContentBlock 5 items | Content Block A (5 tiles)
 */
export default function HomeContentBlockJ({
  data,
  alternateBg,
  dataIndex = 1,
  trendingBlock,
}: Props): ReactElement {
  const ContentBlockThreeItems = data.contentBlock.filter(
    (item) => item.type === "3items",
  );

  const cta =
    trendingBlock == false
      ? {
          name: configHome.contentBlockJ?.viewMore?.text || "VIEW ALL",
          url: ContentBlockThreeItems[dataIndex].url,
          icon: configHome.viewMore?.icon,
        }
      : undefined;

  return (
    <div
      className={cx(
        styles.gutter,
        styles.contentBlockJContainer,
        alternateBg && styles.backgroundFill,
        trendingBlock && styles.trendingAltStyle,
      )}
    >
      {!!ContentBlockThreeItems[dataIndex]?.items.length && (
        <div className={cx(styles.bodyContainer)}>
          <ContentBlock
            inlineGutter={false}
            heading={ContentBlockThreeItems[dataIndex].title}
            cta={cta}
            withDivider={configHome.headingDivider}
            isHeadingClickable={configHome.isHeadingClickable}
          >
            <SectionContext.Provider
              value={{
                uniqueName: ContentBlockThreeItems[dataIndex].uniqSectionName,
              }}
            >
              <div className={styles.contentBlockFourItemsContainer}>
                {ContentBlockThreeItems[dataIndex]?.items?.map(
                  (item, index) => {
                    return (
                      <div key={index} className={styles.contentBlockFourItems}>
                        {index === 0 ? (
                          <div className={styles.bodyContainerJ}>
                            <AnchorBlock
                              {...item}
                              loading="lazy"
                              placeholder={placeholders.landscape}
                              mobileCrop={configTeaser.anchorBlock?.mobileCrop}
                            />
                          </div>
                        ) : (
                          <div
                            className={cx(
                              styles.mDownGutterSides,
                              styles.latestArticleJ,
                            )}
                          >
                            <LatestArticle
                              {...item}
                              mode="landscape"
                              placeholders={placeholders}
                            />
                          </div>
                        )}
                      </div>
                    );
                  },
                )}
              </div>
            </SectionContext.Provider>
          </ContentBlock>
        </div>
      )}
    </div>
  );
}
