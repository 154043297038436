const appleIcon = (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="white" />
    <circle cx="22" cy="22" r="22" fill="#6E6E6E" />
    <path
      d="M26.1647 29.3179C25.2601 30.215 24.2724 30.0734 23.3217 29.6485C22.3156 29.2141 21.3925 29.1952 20.331 29.6485C19.0018 30.2339 18.3003 30.064 17.5065 29.3179C13.002 24.568 13.6666 17.3345 18.7803 17.07C20.0264 17.1361 20.8941 17.7688 21.6233 17.8255C22.7125 17.5989 23.7555 16.9473 24.9186 17.0323C26.3124 17.1456 27.3646 17.7122 28.0569 18.7321C25.177 20.4979 25.8601 24.3791 28.5 25.4651C27.9739 26.8816 27.2908 28.2886 26.1554 29.3274L26.1647 29.3179ZM21.531 17.0134C21.3925 14.9075 23.0632 13.17 24.9832 13C25.2509 15.4364 22.8232 17.2495 21.531 17.0134Z"
      fill="white"
    />
  </svg>
);

const googlePlaystoreIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="44"
    height="44"
    viewBox="0 0 44 44"
  >
    <circle cx="22" cy="22" r="22" fill="white" />
    <circle cx="22" cy="22" r="22" fill="#6E6E6E" />
    <path
      fill="#fff"
      d="m17.4939 15.1548 7.0343 6.7907-7.0336 6.7906c-.125-.0507-.2318-.136-.3068-.2452-.0749-.1091-.1148-.2372-.1146-.3681v-12.354c0-.1309.0398-.2589.1146-.3681.0748-.1092.1813-.1947.3061-.2459Zm7.5227 7.262 1.5902 1.5347-7.5551 4.222 5.9649-5.7567Zm2.2099-2.132 1.939 1.084c.1047.0586.1916.1427.252.2439.0604.1012.0922.216.0922.3328 0 .1167-.0318.2315-.0922.3327-.0604.1012-.1473.1853-.252.2439l-1.9397 1.084-1.7208-1.6606 1.7215-1.6607Zm-8.1748-4.5673 7.5558 4.2213-1.5909 1.5353-5.9649-5.7566Z"
    />
  </svg>
);

const instagramIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="44"
    height="44"
    viewBox="0 0 44 44"
  >
    <circle cx="22" cy="22" r="22" fill="white" />
    <circle cx="22" cy="22" r="22" fill="#6E6E6E" />
    <g fill="#fff">
      <path d="M26.4162 30h-8.8326C15.3318 30 13.5 28.0762 13.5 25.7116v-8.4231C13.5 14.9238 15.3318 13 17.5836 13h8.8326c2.252 0 4.0838 1.9238 4.0838 4.2885v8.4231C30.5 28.0762 28.6682 30 26.4162 30Zm-8.8326-15.7096c-1.5741 0-2.8549 1.3451-2.8549 2.9981v8.4231c0 1.6531 1.2808 2.998 2.8549 2.998h8.8326c1.5741 0 2.8551-1.3449 2.8551-2.998v-8.4231c0-1.653-1.2808-2.9981-2.8551-2.9981h-8.8326Z" />
      <path d="M21.9998 26.1754c-2.4654 0-4.471-2.1063-4.471-4.6954 0-2.5892 2.0056-4.6953 4.471-4.6953 2.4653 0 4.4709 2.1062 4.4709 4.6953 0 2.589-2.0056 4.6954-4.4709 4.6954Zm0-8.1003c-1.7878 0-3.2423 1.5274-3.2423 3.4047s1.4545 3.4051 3.2423 3.4051c1.7877 0 3.2422-1.5276 3.2422-3.4051s-1.4545-3.4047-3.2422-3.4047Zm4.9673-.8389c.4015 0 .7269-.3418.7269-.7634s-.3254-.7633-.7269-.7633c-.4014 0-.7269.3417-.7269.7633s.3255.7634.7269.7634Z" />
    </g>
  </svg>
);

const facebookIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="44"
    height="44"
    viewBox="0 0 44 44"
  >
    <circle cx="22" cy="22" r="22" fill="white" />
    <circle cx="22" cy="22" r="22" fill="#6E6E6E" />
    <path
      fill="#fff"
      d="m25.8465 23.6845.5186-3.4348h-3.2417v-2.2319c0-.9392.4513-1.8573 1.9036-1.8573H26.5v-2.9283c-.8662-.1425-1.7376-.2216-2.6141-.2322-2.6712 0-4.4191 1.6462-4.4191 4.6273v2.6224H16.5v3.4401h2.9668V32h3.6515v-8.3102h2.723l.0052-.0053Z"
    />
  </svg>
);

const youtubeIcon = (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="white" />
    <circle cx="22" cy="22" r="22" fill="#6E6E6E" />
    <path
      d="M32.0609 26.8142C31.8199 27.6745 31.1076 28.3519 30.2043 28.5823C28.5673 29 22 29 22 29C22 29 15.4327 29 13.7957 28.5823C12.8924 28.3519 12.1801 27.6745 11.9391 26.8142C11.5 25.2539 11.5 22 11.5 22C11.5 22 11.5 18.7461 11.9391 17.1858C12.1801 16.3255 12.8924 15.6481 13.7957 15.4177C15.4339 15 22 15 22 15C22 15 28.5673 15 30.2043 15.4177C31.1076 15.6481 31.8199 16.3255 32.0609 17.1858C32.5 18.745 32.5 22 32.5 22C32.5 22 32.5 25.2539 32.0609 26.8142ZM19.8523 19.0453V24.9547L25.3409 22L19.8523 19.0453Z"
      fill="white"
    />
  </svg>
);

const linkedinIcon = (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="white" />
    <circle cx="22" cy="22" r="22" fill="#6E6E6E" />
    <path
      d="M16.94 14.999C16.9397 15.5295 16.7288 16.0381 16.3535 16.413C15.9782 16.7879 15.4694 16.9983 14.939 16.998C14.4086 16.9978 13.9 16.7868 13.5251 16.4116C13.1502 16.0363 12.9397 15.5275 12.94 14.997C12.9403 14.4666 13.1512 13.958 13.5265 13.5831C13.9018 13.2082 14.4106 12.9978 14.941 12.998C15.4714 12.9983 15.98 13.2093 16.3549 13.5845C16.7298 13.9598 16.9403 14.4686 16.94 14.999ZM17 18.479H13V30.999H17V18.479ZM23.32 18.479H19.34V30.999H23.28V24.429C23.28 20.769 28.05 20.429 28.05 24.429V30.999H32V23.069C32 16.899 24.94 17.129 23.28 20.159L23.32 18.479Z"
      fill="white"
    />
  </svg>
);

export {
  appleIcon,
  facebookIcon,
  googlePlaystoreIcon,
  instagramIcon,
  linkedinIcon,
  youtubeIcon,
};
