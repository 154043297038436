import {
  ResolvedButtonElement,
  ResolvedCUEElement,
  ResolvedEmbedCUEElement,
  ResolvedGalleryItemHtmlInfoCUEElement,
} from "@app/layouts/Article/elements";
import styles from "@app/layouts/Article/Storyline.module.css";
import { configGAM } from "@pub/config";
import {
  Ads,
  Button,
  ResponsiveIframe,
  useBodyScrollLock,
  useSidebarToggle,
} from "@sphtech/dmg-design-system";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import LightBoxPortal from "../LightBox";
import RelatedArticleInline from "../RelatedArticleInline";
import GalleryItem from "./GalleryItem";

const Gallery = ({
  element: { children },
  path,
  startcount = 0,
}: {
  element: ResolvedCUEElement;
  path: string;
  startcount: number;
}) => {
  const [isOpen, toggleLightBox] = useSidebarToggle(false);
  const [gotoItem, setgotoItem] = useState(0);
  useBodyScrollLock(isOpen);

  const galleryItemRef = useRef<HTMLDivElement | null>(null);
  const [searchParams] = useSearchParams();
  const slide = Number.parseInt(searchParams.get("slide") ?? "");

  useEffect(() => {
    if (galleryItemRef.current) {
      galleryItemRef.current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  }, [slide]);

  if (!children) return null;

  let galleryItemPosition = 1;
  let lightBoxPosition = 1;
  let relatedStoryIds: string[] = [];

  let adInsertMax = configGAM.galleryInsertMax;
  const adInsertPos = configGAM.galleryInsertPos;

  const totalItems = children.filter(
    (gallery_item) =>
      gallery_item.type === "gallery_items_image" ||
      gallery_item.type === "gallery_items_custom_html",
  ).length;

  function handleFullScreen(index: number, action: string) {
    setgotoItem(index);
    toggleLightBox();
    if (action == "image") {
      window.postMessage(`lightbox-open-image=${index}`);
    } else {
      window.postMessage(`lightbox-open=${index}`);
    }
  }

  return (
    <Fragment>
      <Ads.AdsGallery>
        {children.map((gallery_item, index) => {
          const galleryItems = [];
          let insertedSlide = false;

          if (gallery_item.type === "gallery_items_custom_html") {
            if (!gallery_item.children) return null;
            const lightboxposition = lightBoxPosition++;

            const htmlInfo = gallery_item.children.find(
              (item): item is ResolvedGalleryItemHtmlInfoCUEElement =>
                item.type === "gallery_items_custom_html_info",
            );

            const iframe = gallery_item.children.find(
              (item): item is ResolvedEmbedCUEElement => item.type === "embed",
            );
            const url =
              iframe?.additionalFields.uri?.value?.replace(/"/g, "") || "";

            galleryItems.push(
              <GalleryItem
                key={index}
                info={htmlInfo}
                responsiveIframe={
                  <ResponsiveIframe
                    title={htmlInfo?.additionalFields.description?.value ?? ""}
                    src={url}
                    embedPathAmends={{
                      "www.instagram.com": "captioned/",
                    }}
                    loading="lazy"
                  />
                }
                currentItem={galleryItemPosition++}
                total={totalItems}
                fullScreen={() => {
                  handleFullScreen(lightboxposition, "click");
                }}
                path={path}
                ref={slide === index + 1 ? galleryItemRef : null}
              />,
            );
            insertedSlide = true;
          } else if (gallery_item.type === "gallery_items_image") {
            const lightboxposition = lightBoxPosition++;

            galleryItems.push(
              <GalleryItem
                key={index}
                info={gallery_item}
                currentItem={galleryItemPosition++}
                total={totalItems}
                fullScreen={() => {
                  handleFullScreen(lightboxposition, "click");
                }}
                fullScreenImage={() => {
                  handleFullScreen(lightboxposition, "image");
                }}
                path={path}
                ref={slide === index + 1 ? galleryItemRef : null}
              />,
            );
            insertedSlide = true;
          } else if (
            gallery_item.type === "call_to_action" ||
            gallery_item.type === "shop_button"
          ) {
            const cta =
              gallery_item.type === "call_to_action" && gallery_item.children
                ? (gallery_item.children[0] as ResolvedButtonElement)
                : gallery_item;

            const addtionalFields = cta.additionalFields;

            galleryItems.push(
              <div key={index} className={styles.buttonInlineWrapper}>
                <Button
                  variant="secondary"
                  href={
                    addtionalFields.destinationUrl?.value ??
                    addtionalFields.websiteLink?.value
                  }
                  target="_blank"
                >
                  {addtionalFields.textToDisplay?.value ??
                    addtionalFields.name?.value}
                </Button>
              </div>,
            );
          } else if (
            gallery_item.type === "relation" &&
            gallery_item.relation?.id
          ) {
            // Starts accumulating related stories
            relatedStoryIds.push(gallery_item.relation.id);
            // Only render the component when it is at the end of the group
            if (children[index + 1]?.type !== "relation") {
              galleryItems.push(
                <RelatedArticleInline key={index} ids={relatedStoryIds} />,
              );
              // Reset for next group of related stories
              relatedStoryIds = [];
            }
          } else {
            console.error(
              `[Gallery] Unhandled gallery_item ${gallery_item.type}`,
              gallery_item,
            );
          }

          if (insertedSlide) {
            //adjust count for array index starts from 0
            const slideCount = galleryItemPosition - 2 + startcount;
            const bInsertAd =
              slideCount > 0 &&
              slideCount % adInsertPos == 0 &&
              adInsertMax > 0;
            if (bInsertAd) {
              adInsertMax -= 1;
              //insert ad before the last slide
              galleryItems.splice(-1, 0, <Ads.AdsMidArticle key="ad-1" />);
            }
          }

          return galleryItems;
        })}
      </Ads.AdsGallery>
      <LightBoxPortal
        isOpen={isOpen}
        elements={children}
        gotoItem={gotoItem}
        close={toggleLightBox}
      />
    </Fragment>
  );
};

export default Gallery;
