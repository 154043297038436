function insertImuAt<T>(items: T[], insertAt: number, maxAds: number = 3) {
  let insertedAds = 0;
  return items?.reduce<T[]>((acc, item, index) => {
    acc.push({ ...item, position: index + 1 });
    if ((index + 1) % insertAt == 0 && insertedAds < maxAds) {
      acc.push({ type: "ads", ...item });
      insertedAds++;
    }
    return acc;
  }, []);
}

export { insertImuAt };
