import { useState } from "react";

type SidebarState = boolean;
type ToggleSidebar = () => void;

/**
 * Copied over from app/components/hooks/hook.tsx
 * Used by Base layout
 */
export default function useSidebarToggle(
  initialState: SidebarState = false,
): [SidebarState, ToggleSidebar] {
  const [isOpen, setIsOpen] = useState<SidebarState>(initialState);

  const toggleSidebar: ToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return [isOpen, toggleSidebar];
}
