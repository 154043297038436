import { createContext, useContext } from "react";

export type SectionContextOptions = {
  /** Filter section display down to only show sub section of given uniqSectionName */
  uniqueName?: string;
  /** How much downwards depth to show */
  depth?: 1 | 2 | 3;
};

/** React context for section displays
 * For example if all items displayed are already prefiltered to a specific section you can wrapped them in a SectionContext to avoid repeating the same section name and instead display for example the next section level or just the date via uniqSectionName. Or force full home section tree display (all level) by setting homeTree to true.
 */
const SectionContext = createContext<SectionContextOptions>({});

/** Get SectionContext */
const useSectionContext = () => useContext(SectionContext);

export { useSectionContext, SectionContext };
