import { isDynamicIframe } from "../layouts/Article/elements";

export function extractDynamicIframe(dynamicIframeValue?: string) {
  if (!dynamicIframeValue) {
    return;
  }
  let parsed;
  try {
    parsed = JSON.parse(dynamicIframeValue) as unknown;
  } catch (e) {
    console.error("Failed to parse dynamic iframe value");
    console.error(e);
    return;
  }

  if (!isDynamicIframe(parsed)) {
    console.error("Invalid dynamic iframe value");
    return;
  }

  const convertStringToAspectRation = (aspectRatio?: string) => {
    if (aspectRatio) {
      const [width, height] = aspectRatio.split("/").map(Number);
      if (height === 0 || isNaN(width) || isNaN(height)) {
        return;
      }
      return width / height;
    }
    return;
  };

  const src = parsed.data.find((d) => d.name === "src")?.value;
  if (!src) {
    return;
  }

  const title = parsed.data.find((d) => d.name === "tag")?.value;
  const dataAspectRatio = parsed.data.find(
    (d) => d.name === "data-aspectRatio",
  )?.value;

  const dataMobileAspectRatio = parsed.data.find(
    (d) => d.name === "data-mobile-aspectRatio",
  )?.value;

  const paddingBottom = parsed.data.find(
    (d) => d.name === "padding-bottom",
  )?.value;

  return {
    src,
    title,
    forceAspectRatio: convertStringToAspectRation(dataMobileAspectRatio),
    mobileUp: {
      forceAspectRatio: convertStringToAspectRation(dataAspectRatio),
    },
    paddingBottom,
  };
}
