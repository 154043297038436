import { configHome, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  ContentBlock,
  CustomClassContext,
  LatestArticle,
  useCarousel,
} from "@sphtech/dmg-design-system";
import {
  BlackArrowLeft,
  BlackArrowRight,
} from "@src/app/theme/icons/commonIcons";
import cx from "classnames";
import { ReactNode, useContext } from "react";

import styles from "../homelayout.module.css";
import { HomeContentBlocks, HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataIndex?: number;
  alternateBg?: boolean;
};

// ContentBlock 3 items | content-hub | Content Block B (3 tiles)
export default function HomeContentBlockB({
  data,
  dataIndex = 1,
  alternateBg,
}: Props): ReactNode {
  const ContentBlockThreeItems = data.contentBlock.filter(
    (item): item is HomeContentBlocks => item.type === "3items",
  );

  const { next, prev, hasReachedEnd, hasSlideMoved, refPassthrough } =
    useCarousel(ContentBlockThreeItems[dataIndex]?.items.length || 0, 1);

  const customClass = useContext(CustomClassContext);

  const scrollNavigation = configHome.contentBlockB?.scrollable && (
    <div className={styles.navigation}>
      <button
        className={styles.prev}
        onClick={() => {
          prev();
        }}
        style={{
          display: hasSlideMoved ? "flex" : "none",
        }}
      >
        {BlackArrowLeft}
      </button>
      <button
        className={styles.next}
        onClick={() => {
          next();
        }}
        style={{
          display: hasReachedEnd ? "none" : "flex",
        }}
      >
        {BlackArrowRight}
      </button>
    </div>
  );
  return (
    !!ContentBlockThreeItems[dataIndex]?.items.length && (
      <div
        className={cx(
          alternateBg && styles.backgroundFill,
          styles.contentHubContainer,
          styles.contentBlockBContainer,
        )}
      >
        <div className={cx(styles.bodyContainer, styles.gutter)}>
          <ContentBlock
            heading={ContentBlockThreeItems[dataIndex].title}
            cta={{
              url: ContentBlockThreeItems[dataIndex].url,
              name: configHome.contentBlockB?.viewMore?.text || "VIEW ALL",
              icon: configHome.viewMore?.icon,
            }}
            isHeadingClickable={configHome.isHeadingClickable ?? true}
            withDivider={configHome.headingDivider}
          >
            <div
              style={
                configHome.contentBlockB?.scrollable
                  ? { position: "relative", display: "flex" }
                  : undefined
              }
            >
              <div
                ref={refPassthrough}
                className={cx(
                  configHome.contentBlockB?.scrollable &&
                    styles.contentBlockBScrollabel,
                )}
              >
                <div
                  className={cx(
                    styles.contentBlockContainer,
                    styles.latestTeaserFontOverride,
                    customClass?.homeContentBlockB?.contentBlockContainer,
                  )}
                >
                  {ContentBlockThreeItems[dataIndex].items.map(
                    (contentBlockItem, index) => (
                      <div
                        key={index}
                        className={cx(
                          styles.content,
                          configHome.contentBlockB?.itemCenteredIndex ===
                            index && styles.itemCentered,
                        )}
                      >
                        <LatestArticle
                          {...contentBlockItem}
                          mode={
                            configHome.contentBlockB?.landscapeIndexes?.includes(
                              index,
                            )
                              ? "landscape"
                              : "portrait"
                          }
                          mobileModeOriginal={
                            configHome.latestArticle.mobileModeOriginal
                          }
                          placeholders={placeholders}
                          landscapeImageWidth={
                            configHome.contentBlockB?.landscapeImageWidth || 315
                          }
                          portraitImageWidth={
                            configHome.contentBlockB?.portraitImageWidth || 315
                          }
                          displayPublishDate={configTeaser.publishDate}
                        />
                      </div>
                    ),
                  )}
                </div>
              </div>
              {scrollNavigation}
            </div>
          </ContentBlock>
        </div>
      </div>
    )
  );
}
