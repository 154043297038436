import { AmazonASINItem } from "../types/ServerCustomContext";

export function extractAmazonDynmaicPricing(data: AmazonASINItem) {
  const offerListing = data.Offers?.Listings[0] || undefined;

  if (!offerListing) {
    return null;
  }
  const discountPrice = offerListing.Price.Amount;
  const discountPercentage = offerListing.Price.Savings?.Percentage;
  const savingBasis = offerListing.SavingBasis?.Amount;

  return {
    asin: data.ASIN,
    discountPrice,
    originalPrice: savingBasis,
    discountPercentage,
  };
}

export function isAmazonASINItem(
  item?: Error | AmazonASINItem,
): item is AmazonASINItem {
  return !!item && Object.prototype.hasOwnProperty.call(item, "ASIN");
}
