import { ReactNode } from "react";
import AffiliateProduct from "../../Block/AffiliatesProduct/affiliatesProduct";
import styles from "./affiliatesProductCarousell.module.css";
import { useMediaQuery } from "../../../hooks";
import NaturalCarousel from "../../Utility/NaturalCarousel/NaturalCarousel";

type AffiliatesProduct = React.ComponentProps<typeof AffiliateProduct>;
type AffiliatesProductCarousellProps = {
  noOfItemsInFrame?: number;
  carouselWidthLeeWay?: number;
  items: AffiliatesProduct["item"][];
  itemWidth: AffiliatesProduct["itemWidth"];
  itemHeight: AffiliatesProduct["itemHeight"];
  cta?: AffiliatesProduct["cta"];
};

/**
 * AffiliatesProductCarousell, uses product card component to display products in a carousel.
 *
 */
export default function AffiliatesProductCarousell({
  items,
  itemWidth,
  itemHeight,
  cta,
  noOfItemsInFrame,
  carouselWidthLeeWay,
}: AffiliatesProductCarousellProps): ReactNode {
  const mobileUp = useMediaQuery("screen and (min-width: 1000px)");
  const noOfItems = noOfItemsInFrame ?? 3;
  const leeWay = carouselWidthLeeWay ?? 30;
  const width = mobileUp ? (itemWidth + leeWay) * noOfItems : 290;
  const itemWidthContainer = mobileUp ? itemWidth : 290;

  const enforceDoublePriceLine = items.some(
    (item) => item.discountPrice && item.originalPrice,
  );
  return (
    <div className={styles.container}>
      <div
        className={styles.carouselContainer}
        style={{
          width: width + "px",
        }}
      >
        <NaturalCarousel isLooping={false} showArrowsOnMobile arrowSize={40}>
          {items.map((item, index) => (
            <AffiliateProduct
              cta={cta}
              item={{
                title: item.title,
                discountPriceLabel: item.discountPriceLabel,
                dynamicPricing: item.dynamicPricing,
                discountPrice: item.discountPrice,
                originalPriceLabel: item.originalPriceLabel,
                originalPrice: item.originalPrice,
                discountInPercentage: item.discountInPercentage,
                link: item.link,
                image: item.image,
              }}
              key={index}
              itemWidth={itemWidthContainer}
              itemHeight={itemHeight}
              enforceDoublePriceLine={enforceDoublePriceLine}
            />
          ))}
        </NaturalCarousel>
      </div>
    </div>
  );
}
