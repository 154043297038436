import { createContext, useContext } from "react";

export type DesignContextType = {
  button?: {
    primary?: string | string[];
    secondary?: string | string[];
  };
  divider?: {
    secondary?: string | string[];
  };
  homeContentBlockB?: {
    contentBlockContainer?: string | string[];
  };
  magazineListing?: {
    border?: string | string[];
    divider?: string | string[];
    verticalLine?: string | string[];
  };
  textLink?: string | string[];
  anchorBlock?: {
    verticalLine?: string | string[];
    summaryItem?: string | string[];
  };
  relatedItem?: {
    verticalLine?: string | string[];
    firstItem?: string | string[];
  };
  videoGallery?: {
    verticalLine?: string | string[];
  };
  navLinks?: {
    verticalLineHeader?: string | string[];
    verticalLineFooter?: string | string[];
  };
  callBlock?: {
    verticalLine?: string | string[];
  };
  stickyTitle?: {
    font?: string | string[];
  };
  authorProfile?: {
    verticalLine?: string | string[];
  };
  heading?: {
    headingBase?: string | string[];
    headingSplit?: string | string[];
  };
};

const CustomClassContext = createContext<DesignContextType | null>(null);

const useCustomClassContext = () => useContext(CustomClassContext);

export { useCustomClassContext, CustomClassContext };
