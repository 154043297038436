import { configHome, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  AnchorBlock,
  ContentBlock,
  LatestArticle,
  SectionContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactElement } from "react";

import styles from "../homelayout.module.css";
import { HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  alternateBg?: boolean;
};

/**
 * Description: ContentBlock 5 items | Content Block A (5 tiles)
 */
export default function HomeContentBlockA({
  data,
  alternateBg,
}: Props): ReactElement {
  const ContentBlock5Items = data.contentBlock.filter(
    (item) => item.type === "5items",
  );

  return (
    <div
      className={cx(
        alternateBg && styles.backgroundFill,
        styles.bodyContainer,
        styles.gutterTopBottom,
      )}
    >
      {ContentBlock5Items.length !== 0 && (
        <ContentBlock
          inlineGutter={true}
          heading={ContentBlock5Items[0].title}
          cta={{
            name: "VIEW ALL",
            url: ContentBlock5Items[0].url,
            icon: configHome.viewMore?.icon,
          }}
          withDivider={configHome.headingDivider}
          isHeadingClickable={configHome.isHeadingClickable}
        >
          <SectionContext.Provider
            value={{
              uniqueName: ContentBlock5Items[0].uniqSectionName,
            }}
          >
            <div className={styles.contentBlockFiveItemsContainer}>
              {ContentBlock5Items[0]?.items?.map((item, index) => {
                return (
                  <div key={index} className={styles.contentBlockFiveItems}>
                    {index === 0 ? (
                      <div className={styles.bodyContainer}>
                        <AnchorBlock
                          {...item}
                          loading="lazy"
                          placeholder={placeholders.landscape}
                          mobileCrop={configTeaser.anchorBlock?.mobileCrop}
                        />
                      </div>
                    ) : (
                      <div className={styles.mDownGutterSides}>
                        <LatestArticle
                          {...item}
                          mode={index === 4 ? "portrait" : "landscape"}
                          placeholders={placeholders}
                          displayPublishDate={configTeaser.publishDate}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </SectionContext.Provider>
        </ContentBlock>
      )}
    </div>
  );
}
