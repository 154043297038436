import cx from "classnames";
import * as React from "react";

import SocialIcon, { SocialIconProps } from "../../Block/SocialIcon/SocialIcon";
import Divider from "../../Element/Divider/divider";
import Logo from "../../Element/Logo/Logo";
import * as Typography from "../../Element/Typography";
import SPHInvertedLogo from "./SPH_media_invert.svg";
import SPHLogo from "./SPH_media_logo.svg";
import styles from "./index.module.css";
import { useCustomClassContext } from "../../../hooks/useCustomClassContext/CustomClassContext";

export type FooterProps = {
  /* this is to display a navigation menu */
  navLinks: {
    text: string;
    url: string;
    target?: "_blank";
  }[];
  /* this is to display a social media platform(s) */
  socialIconProps?: SocialIconProps;
  /* this is an option to customised the logo based on each publication(s) need */
  logoProps: React.ComponentProps<typeof Logo>;
  /* set to either true or false in order to display divider */
  withDivider?: boolean;
  mciText?: React.ReactNode;
  /* set to true to display the inverted SPH logo */
  sphLogoInverted?: boolean;
};

export default function Footer({
  navLinks,
  socialIconProps,
  logoProps,
  sphLogoInverted = false,
  withDivider = true,
  mciText,
}: FooterProps): React.ReactElement {
  const { NavLink } = Typography;
  const customClass = useCustomClassContext();

  return (
    <footer className={cx(styles.footer, styles.footerTemplate)}>
      {/* gridArea: "divider" */}
      {withDivider && (
        <div className={styles.footerDivider}>
          <Divider />
        </div>
      )}

      {/* gridArea: "logo" */}
      <div className={styles.logoContainer}>
        <div className={styles.logo}>
          <Logo {...logoProps} width="100%" height="100%" />
        </div>
      </div>

      {/* gridArea: "social" */}
      {socialIconProps && (
        <div className={styles.socialmediaContainer}>
          <SocialIcon {...socialIconProps} />
        </div>
      )}

      {/* gridArea: "nav" */}
      <div className={styles.navlinksContainer}>
        {navLinks &&
          navLinks.map(({ text, url, target }, index) => (
            <div
              className={cx(
                styles.navlinks,
                customClass?.navLinks?.verticalLineFooter,
              )}
              key={index}
            >
              <NavLink key={index} href={url} target={target}>
                {text}
              </NavLink>
            </div>
          ))}
      </div>

      {/* gridArea: "sph" */}
      <div className={styles.sph_logo}>
        <Logo
          src={(sphLogoInverted && SPHInvertedLogo) || SPHLogo}
          href="https://www.sph.com.sg/"
          width="100%"
          height="auto"
          alt="SPH Logo"
          loading="lazy"
        />
      </div>

      {/* gridArea: "mci" */}
      <div className={styles.mciTextContainer}>
        <div className={styles.mciText} suppressHydrationWarning>
          {mciText}
        </div>
      </div>
    </footer>
  );
}
