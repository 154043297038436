import { configLotame } from "@pub/config";
import { loadScript } from "@sphtech/dmg-design-system";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

export default configLotame.active ? AnalyticsLotame : () => null;

function AnalyticsLotame() {
  useEffect(() => {
    const lotameFile = `https://tags.crwdcntrl.net/lt/c/${configLotame.clientId}/lt.min.js`;
    void loadScript(lotameFile, true);
  }, []);
  return (
    <Helmet>
      <link rel="preconnect" href="https://tags.crwdcntrl.net" />
      <link rel="preconnect" href="https://bcp.crwdcntrl.net" />
      <link rel="dns-prefetch" href="https://tags.crwdcntrl.net" />
      <link rel="dns-prefetch" href="https://bcp.crwdcntrl.net" />
      <script>
        {`
          !function() {
          // Lotame config
          var lotameClientId = '${configLotame.clientId}';
          var lotameTagInput = {
            data: {},
            config: {
              clientId: Number(lotameClientId),
            }
          };
          // Lotame initialization
          var lotameConfig = lotameTagInput.config || {};
          var namespace = window['lotame_' + lotameConfig.clientId] = {};
          namespace.config = lotameConfig;
          namespace.data = lotameTagInput.data || {};
          namespace.cmd = namespace.cmd || [];
        } ();
      `}
      </script>
    </Helmet>
  );
}
