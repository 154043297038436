import { configHome, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  ContentBlock,
  LatestArticle,
  SectionContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { HomeContentBlocks, HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataIndex?: number;
  alternateBg?: boolean;
};

// ContentBlock 3 items | Trending | Content Block D
export default function HomeContentBlockD({
  data,
  dataIndex = 0,
  alternateBg,
}: Props): ReactNode {
  const ContentBlockThreeItems = data.contentBlock.filter(
    (item): item is HomeContentBlocks => item.type === "3items",
  );

  return (
    !!ContentBlockThreeItems[dataIndex]?.items.length && (
      <div
        className={cx(
          alternateBg && styles.backgroundFill,
          styles.trendingContainer,
        )}
      >
        <div className={cx(styles.bodyContainer, styles.gutter)}>
          <ContentBlock
            heading={ContentBlockThreeItems[dataIndex].title}
            cta={{
              name: ContentBlockThreeItems[dataIndex].cta
                ? configHome.contentBlockF?.viewMore?.text || "VIEW ALL"
                : undefined,
              url: ContentBlockThreeItems[dataIndex].url,
              icon: configHome.viewMore?.icon,
            }}
            withDivider={configHome.headingDivider}
            isHeadingClickable={configHome.isHeadingClickable}
          >
            <SectionContext.Provider
              value={{
                uniqueName: ContentBlockThreeItems[dataIndex].uniqSectionName,
              }}
            >
              <div className={styles.contentBlockContainer}>
                {ContentBlockThreeItems[dataIndex].items.map(
                  (contentBlockItem, index) => {
                    return (
                      <div className={styles.content} key={index}>
                        <LatestArticle
                          {...contentBlockItem}
                          mode={
                            configHome.contentBlockD?.squareIndexes?.includes(
                              index,
                            )
                              ? "square"
                              : configHome.contentBlockD?.crop || "portrait"
                          }
                          placeholders={placeholders}
                          descriptionInTitle={
                            configHome.contentBlockD?.descriptionInTitle
                          }
                          displayPublishDate={configTeaser.publishDate}
                        />
                      </div>
                    );
                  },
                )}
              </div>
            </SectionContext.Provider>
          </ContentBlock>
        </div>
      </div>
    )
  );
}
