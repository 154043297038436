import styles from "./PageShare.module.css";
import SocialIcon, { SocialIconProps } from "../SocialIcon/SocialIcon";
import { SubHeading4 } from "../../Element/Typography/Heading/Heading";
import { ReactNode } from "react";

export type PageShareProps = {
  label: React.ReactNode;
  url: string;
  title?: string;
  size?: number;
  media?: SocialIconProps["icons"];
  fill?: string;
};

const PageShare = ({
  label,
  url,
  title,
  size,
  media,
  fill,
}: PageShareProps): ReactNode => {
  return (
    media && (
      <div className={styles.pageShareContainer}>
        <div className={styles.pageShareLabel}>
          <SubHeading4>{label}</SubHeading4>
        </div>
        <div className={styles.pageShareSocialIconContainer}>
          <SocialIcon
            icons={media}
            target="_blank"
            size={size}
            fill={fill}
            pageToShare={{
              url,
              title,
            }}
          />
        </div>
      </div>
    )
  );
};

export default PageShare;
