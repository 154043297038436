import { ReactNode, ReactElement } from "react";
import styles from "./textlink.module.css";
import cx from "classnames";
import { useCustomClassContext } from "../../../../hooks/useCustomClassContext/CustomClassContext";

export type Props = {
  children: ReactNode;
  href: string;
  target?: string;
};

/**
 *
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function TextLink({
  children,
  href,
  target,
}: Props): ReactElement {
  const customClass = useCustomClassContext();
  return (
    <a
      className={cx(styles.cta, customClass?.textLink)}
      href={href}
      target={target}
    >
      {children}
    </a>
  );
}
