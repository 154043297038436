import React, { ReactElement, ReactNode } from "react";
import * as Typography from "../../Element/Typography";
import { SectionSubHeading } from "../../Block/SectionHeading/SectionHeading";
import styles from "./contentblock.module.css";
import cx from "classnames";

const TextLink = Typography.TextLink;

type ContentBlockProps = {
  heading: string;
  children?: ReactNode;
  arrows?: ReactNode;
  inlineGutter?: boolean;
  cta?: {
    name?: string;
    url: string;
    icon?: ReactNode;
  };
  isHeadingClickable?: boolean;
  imu?: ReactNode;
  withDivider?: boolean;
  description?: string;
};

const ContentBlock: React.FC<ContentBlockProps> = ({
  heading,
  arrows,
  inlineGutter = false,
  cta,
  children,
  isHeadingClickable = false,
  imu,
  withDivider = true,
  description,
}): ReactElement => {
  return (
    <div className={styles.contentBlockContainer}>
      <div className={inlineGutter ? styles.mDownGutterSides : undefined}>
        <SectionSubHeading
          title={heading}
          arrows={arrows}
          headingUrl={(isHeadingClickable && cta && cta.url) || ""}
          withDivider={withDivider}
          description={description}
        />
      </div>
      <div className={cx(imu && styles.innerContainer)}>
        <div>
          {children}
          {cta?.name && (
            <div className={styles.ctaContainer}>
              <TextLink href={cta.url}>
                {cta.name}
                {cta.icon}
              </TextLink>
            </div>
          )}
        </div>
        {imu && <div className={styles.imuContainer}>{imu}</div>}
      </div>
    </div>
  );
};

export default ContentBlock;
