import { CSSProperties } from "react";
//import our css
import styles from "./newsticker.module.css";
//import from library
import { VerticalTicker } from "./Lib/VerticalTicker";

/**
 * This is newsticker component
 */
const NewsTicker: React.FC<{
  sourceFrom: string;
  items: {
    title: string;
    link: string;
  }[];
  duration?: number;
  layout?: "vertical" | "horizontal";
}> = ({ sourceFrom, duration: propDuration, layout = "vertical", items }) => {
  const duration = propDuration || (layout == "horizontal" ? 40 : 15);

  const charLength = items.reduce(
    (total, current) => total + current.title.length,
    0,
  );
  // Constant estimated from charLength in storybook example
  const MULTIPLIER_CONSTANT = 500;
  const newDuration = duration * (charLength / MULTIPLIER_CONSTANT);

  return (
    <div className={styles.newstickerContainer}>
      <div className={styles.newstickerLabel}>
        <b>{sourceFrom}</b>
      </div>
      <div className={styles.newstickerBoxContent}>
        {layout === "horizontal" ? (
          <div
            className={styles.newstickerHorizontalContainer}
            style={
              {
                "--newsticker-horizontal-duration": `${newDuration}s`,
              } as CSSProperties
            }
          >
            <div className={styles.newstickerHorizontalContentWrapper}>
              {items.map((item) => (
                <a
                  key={item.link}
                  className={styles.newstickerHorizontalItem}
                  href={item.link}
                  target="_blank"
                  rel="noopener"
                >
                  {item.title}
                </a>
              ))}
            </div>
          </div>
        ) : (
          <div className={styles.newstickerVerticalContainer}>
            <VerticalTicker duration={1000 * duration}>
              {items.map((item) => (
                <div key={item.link} className={styles.newstickerVerticalItem}>
                  <a href={item.link} target="_blank" rel="noopener">
                    {item.title}
                  </a>
                </div>
              ))}
            </VerticalTicker>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsTicker;
