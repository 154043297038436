import amazonPricingDataLoader from "@src/app/utils/amazonPricingDataloader";
import { formatDiscount, formatPrice } from "@src/app/utils/product";
import useSWR from "swr";

function DynamicPricing({ asin }: { asin: string }) {
  const { data, isLoading } = useSWR(asin, (key) =>
    amazonPricingDataLoader.load(key),
  );

  const amazonApiData = !isLoading && data;
  if (!amazonApiData) {
    return null;
  }

  return (
    <span>
      <b>{formatPrice(amazonApiData.discountPrice)}</b>&nbsp;&nbsp;
      {amazonApiData.originalPrice && (
        <span
          style={{
            textDecoration: "line-through",
          }}
        >
          {formatPrice(amazonApiData.originalPrice)}
        </span>
      )}
      &nbsp;&nbsp;
      <i>{formatDiscount(amazonApiData.discountPercentage, false, true)}</i>
    </span>
  );
}

export default DynamicPricing;
