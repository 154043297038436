import PubBaseLayout from "@pub/BaseLayout";
import MetaData from "@src/app/components/Modules/MetaData";
import { DEFAULT_SEARCH_SORT } from "@src/app/constants";
import SearchLayout from "@src/app/layouts/Search/SearchLayout";
import { TeaserDataApiResponse } from "@src/app/types/ServerCustomContext";
import AdSettings from "@src/app/utils/AdSettings";
import { FetchError } from "@src/app/utils/swr";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useSWRInfinite from "swr/infinite";

const VALID_SORTS = ["latest", "score"];

export default function Search() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchSetting, setSearchSetting] = useState(DEFAULT_SEARCH_SORT);

  useEffect(() => {
    const { searchParams } = new URL(window.location.href);
    setSearchTerm(searchParams.get("q") || "");
    const sortQuery = searchParams.get("sort");
    setSearchSetting(
      sortQuery && VALID_SORTS.includes(sortQuery)
        ? sortQuery
        : DEFAULT_SEARCH_SORT,
    );
  }, []);

  const location = useLocation();

  const handleDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newSearchSetting = event.target.value;
    setSearchSetting(newSearchSetting);
    navigate(
      `?q=${encodeURIComponent(searchTerm)}${newSearchSetting === DEFAULT_SEARCH_SORT ? "" : `&sort=${encodeURIComponent(newSearchSetting)}`}`,
    );
  };

  const getSearchUrl = (_: number, prev?: TeaserDataApiResponse) => {
    if (!searchTerm) return;
    const params = [
      "size=10",
      `q=${encodeURIComponent(searchTerm)}`,
      searchSetting === DEFAULT_SEARCH_SORT
        ? ""
        : `sort=${encodeURIComponent(searchSetting)}`,
      prev?.endCursor && `after=${encodeURIComponent(prev.endCursor)}`,
    ]
      .filter(Boolean)
      .join("&");
    return `/_plat/api/teaser/search?${params}`;
  };
  const { data, isLoading, error, size, setSize } = useSWRInfinite<
    TeaserDataApiResponse,
    FetchError
  >(getSearchUrl, {
    revalidateFirstPage: false,
    initialSize: searchTerm ? 1 : 0,
  });
  const hasMoreData = !!data?.[size - 1]?.endCursor;
  const isLoadingMore = size > 1 && typeof data?.[size - 1] === "undefined";
  const loadMore = hasMoreData
    ? () => {
        void setSize((size) => (size += 1));
      }
    : undefined;

  useEffect(() => {
    if (searchTerm) window.postMessage("search-reset");
  }, [searchTerm]);

  const firstData = data?.[0];
  useEffect(() => {
    if (firstData) {
      window.postMessage("search-result");
    }
  }, [firstData]);

  return (
    <PubBaseLayout>
      <MetaData
        title={searchTerm ? `You searched for ${searchTerm}` : "Search"}
        type="CollectionPage"
        path={`/search${
          searchTerm ? `?q=${encodeURIComponent(searchTerm)}` : ""
        }`}
      />
      <AdSettings
        pageType="search"
        sectionName="Search"
        adUnits="lb1,lb2,imu1,imu2,catfish,prestitial"
        pageTitle="Search"
        currentLocation={location}
      />

      <SearchLayout
        onSearch={(fieldValue) => {
          setSearchTerm(fieldValue);
          //lets update the url without having to load the page
          if (searchSetting === DEFAULT_SEARCH_SORT) {
            navigate(`?q=${encodeURIComponent(fieldValue)}`);
          } else {
            navigate(
              `?q=${encodeURIComponent(fieldValue)}&sort=${encodeURIComponent(searchSetting)}`,
            );
          }
        }}
        searchTerm={searchTerm}
        searchSetting={searchSetting}
        updateSearchSetting={handleDropdownChange}
        isLoading={isLoading}
        error={error}
        data={data}
        loadMore={loadMore}
        isLoadingMore={isLoadingMore}
      />
    </PubBaseLayout>
  );
}
