import cx from "classnames";

import styles from "./table.module.css";

type TableHeadProps = {
  rowData: string[];
};

const TableHead = ({ rowData }: TableHeadProps) => {
  return (
    <thead>
      <tr className={styles.tableHeadRow}>
        {rowData.map((cell, colIndex) => (
          <th key={colIndex} className={cx(styles.cellStyles, styles.fontBold)}>
            {cell}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
