type goToPositionProps = {
  container: HTMLDivElement;
  currentPosition: number;
  newPosition: number | "next" | "previous";
  isLooping?: boolean;
};

export const goToPosition = ({
  container,
  currentPosition,
  newPosition,
  isLooping,
}: goToPositionProps) => {
  const numberOfChildren = container.children.length;

  const containerRect = container.getBoundingClientRect();
  // Assume all children are of the same width
  const itemWidth = container.children[0].getBoundingClientRect().width;

  const itemsVisible = Math.min(
    numberOfChildren,
    Math.floor(containerRect.width / itemWidth) || 1,
  );

  let safeNewPosition =
    newPosition === "next"
      ? currentPosition + itemsVisible
      : newPosition === "previous"
        ? currentPosition - itemsVisible
        : newPosition;

  const safeLastIndex = numberOfChildren - itemsVisible;

  if (safeNewPosition > safeLastIndex) {
    safeNewPosition =
      isLooping && currentPosition >= safeLastIndex ? 0 : safeLastIndex;
  } else if (safeNewPosition < 0) {
    safeNewPosition = isLooping && currentPosition === 0 ? safeLastIndex : 0;
  }

  const currentItemRect =
    container.children[safeNewPosition].getBoundingClientRect();

  container.scrollTo({
    left: container.scrollLeft + currentItemRect.left - containerRect.left,
    behavior: "smooth",
  });

  return {
    newPosition: safeNewPosition,
    hasPrevious: isLooping ? true : safeNewPosition > 0,
    hasNext: isLooping ? true : safeNewPosition < safeLastIndex,
  };
};
