import { useEffect } from "react";

export const NoAds = () => {
  useEffect(() => {
    document.body.classList.add("noads");
    return () => {
      document.body.classList.remove("noads");
    };
  }, []);
  return null;
};
