import { TeaserData } from "@app/types/ServerCustomContext";
import { configContentHub, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import { LatestArticle } from "@sphtech/dmg-design-system";
import { ReactElement } from "react";

import styles from "./contenthub.module.css";

type Props = {
  data: {
    listedStories?: TeaserData[];
  };
  displayPlayIcon?: boolean;
};

/**
 * ContentHubStoryLayout
 * Description: Render content-hub stories
 * @param props - The props required by the component
 * @returns The rendered React element
 */
export default function ContentHubStoryListingLayout({
  data,
  displayPlayIcon,
}: Props): ReactElement {
  // Add component logic and state here

  return (
    <>
      {data.listedStories && (
        <div className={styles.contentBlockContainer}>
          <div className={styles.contentBlockLayout}>
            {data.listedStories.map((teaser, index: number) => (
              <div key={index} className={styles.contentBlockItem}>
                <LatestArticle
                  key={index}
                  {...teaser}
                  mode={
                    configContentHub.configListingStories?.mode || "portrait"
                  }
                  placeholders={placeholders}
                  displayPublishDate={configTeaser.publishDate}
                  displayPlayIcon={displayPlayIcon}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
