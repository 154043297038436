import { Button } from "@sphtech/dmg-design-system";
import { ReactNode } from "react";

import { ResolvedCUEElement } from "../elements";
import styles from "../Storyline.module.css";
import CallToActionImage from "./CallToActionImage";

type CallToActionProps = {
  element: ResolvedCUEElement;
  variant: "primary" | "secondary";
  bodyMaxWidth: number;
};

/**
 * CallToAction
 * Description: Render a clickable button
 * @param props - The props required by the component
 * @returns The rendered React element
 */
export default function CallToAction({
  element,
  variant,
  bodyMaxWidth,
}: CallToActionProps): ReactNode {
  if (element.type === "shop_button") {
    const addtionalFields = element.additionalFields;
    const textToDisplay =
      addtionalFields.textToDisplay?.value ?? addtionalFields.name?.value;

    return (
      <div className={styles.buttonBlockWrapper}>
        <Button
          variant={variant}
          data-event-label={textToDisplay}
          href={
            addtionalFields.destinationUrl?.value ??
            addtionalFields.websiteLink?.value
          }
          target="_blank"
        >
          {textToDisplay}
        </Button>
      </div>
    );
  }

  return element.children?.map((cta, index) => {
    if (cta.type === "call_to_action_image") {
      return (
        <div key={index} className={styles.buttonBlockWrapper}>
          <CallToActionImage
            element={cta}
            variant={variant}
            bodyMaxWidth={bodyMaxWidth}
          />
        </div>
      );
    }
    if (cta.type === "call_to_action_text") {
      const addtionalFields = cta.additionalFields;
      const textToDisplay =
        addtionalFields.textToDisplay?.value ?? addtionalFields.name?.value;

      return (
        <span key={index} className={styles.buttonInlineWrapper}>
          <Button
            variant={variant}
            data-event-label={textToDisplay}
            href={
              addtionalFields.destinationUrl?.value ??
              addtionalFields.websiteLink?.value
            }
            target="_blank"
          >
            {textToDisplay}
          </Button>
        </span>
      );
    }

    return null;
  });
}
