import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import cx from "classnames";

type Props = {
  children: ReactNode;
  text: ReactNode;
};

const xPadding = 15;
const yPadding = 5;
const arrowSize = 10;
const defaultPosition = {
  x: -xPadding,
  y: 0,
  arrowX: xPadding,
  placement: "bottom",
};

export default function ToolTip({ children, text }: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState(defaultPosition);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const tooltipRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!isVisible) {
      setPosition(defaultPosition);
      return;
    }

    if (!triggerRef.current || !tooltipRef.current) {
      return;
    }

    const triggerRect = triggerRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();

    const viewport = {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    };
    const spaceAvailable = {
      right: viewport.width - triggerRect.right,
      bottom: viewport.height - triggerRect.bottom,
      top: triggerRect.top,
    };

    let xOffset = xPadding;
    // not enough space to fit on right hand?
    if (spaceAvailable.right < tooltipRect.width + xPadding) {
      xOffset = tooltipRect.width - spaceAvailable.right - xPadding;
    }

    const placement =
      spaceAvailable.bottom > tooltipRect.height + yPadding + arrowSize
        ? "bottom"
        : "top";

    setPosition({
      x: -xOffset,
      y:
        placement === "top"
          ? -tooltipRect.height - arrowSize - yPadding
          : triggerRect.height + arrowSize + yPadding,
      arrowX: xOffset,
      placement,
    });

    const onNextClick = (e: MouseEvent) => {
      // ignore clicks on trigger
      // - handled by on click on trigger itself
      if (e.target !== triggerRef.current) {
        setIsVisible(false);
      }
    };
    document.addEventListener("click", onNextClick);
    return () => {
      document.removeEventListener("click", onNextClick);
    };
  }, [isVisible]);

  const hoverBoxStyle: CSSProperties & {
    "--arrow-left": string;
    "--arrow-size": string;
  } = {
    left: position.x,
    top: position.y,
    "--arrow-left": `${position.arrowX}px`,
    "--arrow-size": `${arrowSize}px`,
  };

  return (
    <button
      className={styles.container}
      ref={triggerRef}
      onPointerEnter={(e) => {
        if (e.pointerType !== "touch") {
          setIsVisible(true);
        }
      }}
      onClick={(e) => {
        // prevent upstream like e.g. label to toggle consent box
        e.preventDefault();
        setIsVisible(!isVisible);
      }}
      onPointerLeave={(e) => {
        if (e.pointerType !== "touch") {
          setIsVisible(false);
        }
      }}
    >
      {children}
      {isVisible && (
        <span
          className={cx(
            styles.hoverBox,
            position.placement === "bottom"
              ? styles.arrowTop
              : styles.arrowBottom,
          )}
          ref={tooltipRef}
          style={hoverBoxStyle}
        >
          {text}
        </span>
      )}
    </button>
  );
}
