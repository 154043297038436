import { Fragment, ReactNode, useEffect, useState } from "react";
import ResponsiveImage from "../../Element/ResponsiveImage";
import ResponsiveIframe from "../../Element/ResponsiveIframe";
import Icon from "../../Element/Icon/icon";

import { useCarousel } from "../../../hooks";
import styles from "./embeddedgallery.module.css";
import EmbeddedAction from "../../Block/EmbeddedAction/EmbeddedAction";
import { insertImuAt } from "../../Utility/Items/Items";
import { ImageCrop } from "../../Utility/dataTypes";

type EmbeddedAction = React.ComponentProps<typeof EmbeddedAction>;
type Props = {
  items: GalleryItem[];
  srcWidth: number;
  srcHeight: number;
  leftIcon: ReactNode;
  rightIcon: ReactNode;
  actionbar: {
    shareIcon: EmbeddedAction["shareIcon"];
    onFullScreen: (goto: number, action: string) => void;
    fullScreenIcon: EmbeddedAction["fullScreenIcon"];
  };
  ImuInsertAt: number;
  Imu: ReactNode;
  slidePosition?: number;
  shareMedia?: string[];
  onSlideChange?: (arg0: number, arg1: string, arg2: string) => void;
  onSlideClick?: (arg0: number, arg1: string, arg2: string) => void;
};

type GalleryItem = {
  title: ReactNode;
  description?: ReactNode;
  caption: EmbeddedAction["caption"];
  type?: string;
  position?: number;
  videoUrl?: string;
  image?: ImageCrop;
  shareUrl?: string;
  appendedGalleryItem?: ReactNode;
};

/**
 *
 * Description: EmbeddedGallery component use in the body.
 */
export default function EmbeddedGallery({
  items,
  srcWidth,
  srcHeight,
  leftIcon,
  rightIcon,
  Imu,
  ImuInsertAt = 2,
  actionbar,
  slidePosition,
  shareMedia,
  onSlideChange = () => {},
  onSlideClick = () => {},
}: Props): ReactNode {
  // create a new array with ads at the insertAt position.
  const itemsWithImu = insertImuAt(items, ImuInsertAt);
  // position with ads based on URL slide position
  const carouselPosition = itemsWithImu.findIndex(
    (item) => item.position === slidePosition,
  );

  const {
    next,
    prev,
    position,
    hasReachedEnd,
    hasSlideMoved,
    refPassthrough,
    TouchHandlers,
    goto,
  } = useCarousel(itemsWithImu.length, 1);
  const [isAds, setIsAds] = useState(false);

  //check if the current item is an ad
  useEffect(() => {
    if (itemsWithImu[position]?.type === "ads") {
      setIsAds(true);
    } else {
      setIsAds(false);
    }
  }, [itemsWithImu, position]);

  const galleryContainerNavigation = (
    <div className={styles.navigation}>
      <button
        className={styles.prev}
        style={{ display: hasSlideMoved ? "flex" : "none" }}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          prev();
          const eGalleryId = event.currentTarget
            .closest("[data-gallery-id]")
            ?.getAttribute("data-gallery-id") as string;
          onSlideChange(position - 1, "prev", eGalleryId);
        }}
      >
        <Icon>{leftIcon}</Icon>
      </button>
      <button
        style={{ display: hasReachedEnd ? "none" : "flex" }}
        className={styles.next}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          next();
          const eGalleryId = event.currentTarget
            .closest("[data-gallery-id]")
            ?.getAttribute("data-gallery-id") as string;
          onSlideChange(position + 1, "next", eGalleryId);
        }}
      >
        <Icon>{rightIcon}</Icon>
      </button>
    </div>
  );

  useEffect(() => {
    // carouselPosition will be -1 if not found
    if (carouselPosition > 0) {
      goto(carouselPosition);
    }
  }, [carouselPosition, goto]);

  return (
    <div className={styles.container}>
      <div
        style={{
          visibility: isAds ? "hidden" : "visible",
        }}
      >
        {itemsWithImu[position]?.title}
      </div>

      <div style={{ position: "relative", display: "flex" }}>
        <div
          className={styles.embeddedgalleryContainer}
          style={{ aspectRatio: srcWidth / srcHeight }}
          {...TouchHandlers}
          ref={refPassthrough}
        >
          {itemsWithImu?.map((item, index) => (
            <div
              key={index}
              className={styles.embeddedgalleryItemContainer}
              style={{ aspectRatio: srcWidth / srcHeight }}
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                const eGalleryId = event.currentTarget
                  .closest("[data-gallery-id]")
                  ?.getAttribute("data-gallery-id") as string;
                onSlideClick(position, "click", eGalleryId);
              }}
            >
              {item.type === "ads" ? (
                <Fragment>{Imu}</Fragment>
              ) : (
                <>
                  {item.image && (
                    <ResponsiveImage
                      {...item.image}
                      fill="contain"
                      displayWidth={srcWidth}
                    />
                  )}
                  {item.videoUrl && (
                    <div
                      style={{
                        width:
                          item.videoUrl.includes("instagram") ||
                          item.videoUrl.includes("tiktok") ||
                          item.videoUrl.includes("youtube.com/shorts")
                            ? "fit-content"
                            : // avoid overlap of e.g. youtube interface with prev and next arrow
                              "calc(100% - 100px)",
                      }}
                    >
                      <ResponsiveIframe src={item.videoUrl} loading="lazy" />
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
        {galleryContainerNavigation}
      </div>
      <div
        className={styles.actionBar}
        style={{
          visibility: isAds ? "hidden" : "visible",
        }}
      >
        <EmbeddedAction
          caption={itemsWithImu[position]?.caption}
          pageinationLabel={`${itemsWithImu[position]?.position ?? ""}/${
            items.length
          }`}
          shareIcon={actionbar.shareIcon}
          onFullScreen={() =>
            actionbar.onFullScreen(
              itemsWithImu[position]?.position || 0,
              "click",
            )
          }
          fullScreenIcon={actionbar.fullScreenIcon}
          shareUrl={
            itemsWithImu[position]?.shareUrl && itemsWithImu[position]?.position
              ? `${itemsWithImu[position].shareUrl}&eslide=${itemsWithImu[position].position}`
              : ""
          }
          shareMedia={shareMedia}
        />
      </div>

      <div
        style={{
          visibility: isAds ? "hidden" : "visible",
        }}
      >
        {itemsWithImu[position]?.description}
        {itemsWithImu[position]?.appendedGalleryItem}
      </div>
    </div>
  );
}
