import { loadScript } from "@sphtech/dmg-design-system";
import { DISABLE_ADS } from "@src/app/constants";
import { useEffect } from "react";

export default !DISABLE_ADS ? AnalyticsSUID : () => null;

function AnalyticsSUID() {
  useEffect(() => {
    void loadScript(
      "https://dsuwzj1tch87b.cloudfront.net/suid/suid.min.js",
      false,
    );
  }, []);
  return null;
}
