import { ResponsiveImage, Typography } from "@sphtech/dmg-design-system";
import { Annotation } from "@src/app/layouts/Article/StorylineElements/Annotation/Annotation";
import { ContentHubData } from "@src/app/types/ServerCustomContext";
import { ReactNode } from "react";

import styles from "../contenthub.module.css";

const { Heading, Body } = Typography;

type ContentHubEditorsNoteProps = {
  editorsNote?: ContentHubData["editorsNote"];
};

export default function ContentHubEditorsNote({
  editorsNote,
}: ContentHubEditorsNoteProps): ReactNode {
  return (
    editorsNote && (
      <div className={styles.editorsNoteContainer}>
        <div className={styles.editorsNoteImage}>
          {editorsNote.editorsImage && (
            <ResponsiveImage {...editorsNote.editorsImage} displayWidth={320} />
          )}
          {editorsNote.editorsSignatureImage && (
            <ResponsiveImage
              {...editorsNote.editorsSignatureImage}
              displayWidth={320}
            />
          )}
        </div>
        <div className={styles.editorsNoteTextContainer}>
          <Heading.SubHeading>
            <i>{editorsNote.heading}</i>
          </Heading.SubHeading>

          {editorsNote.description && (
            <Body>
              <Annotation element={editorsNote.description} />
            </Body>
          )}
        </div>
      </div>
    )
  );
}
