import ErrorLayout from "@app/layouts/ErrorLayout/ErrorLayout";
import MetaData from "@components/Modules/MetaData";
import PubBaseLayout from "@pub/BaseLayout";
import { ResponsiveImage } from "@sphtech/dmg-design-system";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import AuthorBlock from "@src/app/components/Modules/AuthorBlock/AuthorBlock";
import { STATIC_PAGE } from "@src/app/constants";
import { ResovledAuthorProfileElement } from "@src/app/layouts/Article/elements";
import Storyline from "@src/app/layouts/Article/Storyline";
import { StaticContent } from "@src/app/types/ServerCustomContext";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "./aboutus.module.css";

type AboutUsData = StaticContent;

export default function AboutUsPage(): ReactNode {
  const routeContext: CustomContext<TRouteWithRedirect<AboutUsData, string>> =
    useRouteContext();

  const { context: response } = routeContext;

  if (response.type !== ResponseType.SUCCESS) {
    return <ErrorLayout response={response} />;
  }

  const { processedElements } = response.payload;
  const { headline, coverImage, bodyElements } = processedElements;

  const profileElements = bodyElements.filter(
    (e): e is ResovledAuthorProfileElement => e.type === "relation_profile",
  );

  let profileInjectionIndex = bodyElements.findIndex(
    (e) => e.type === "relation_profile",
  );

  if (
    profileInjectionIndex > 0 &&
    bodyElements[profileInjectionIndex - 1].type === "sub_head"
  ) {
    profileInjectionIndex = profileInjectionIndex - 1;
  }

  const withoutProfiles = bodyElements.filter(
    (e) => e.type !== "relation_profile",
  );

  const originalCrop = coverImage?.crops.original;

  return (
    <PubBaseLayout ads={false}>
      <MetaData
        title="About Us"
        type="WebPage"
        path="/about-us"
        image={coverImage?.crops.landscape}
      />
      {originalCrop && (
        <div className={styles.headingBackgroundContainer}>
          <ResponsiveImage {...originalCrop} displayWidth={2000} />
          <div className={styles.headingContainer}>{headline}</div>
        </div>
      )}
      <div className={cx(styles.bodyContainer, styles.gutter)}>
        <div className={cx(styles.storylineContainer, styles.intro)}>
          <Storyline
            bodyMaxWidth={800}
            path="/about-us"
            typeName={STATIC_PAGE}
            excludeAds={true}
            elements={withoutProfiles.slice(0, profileInjectionIndex)}
          />
        </div>
      </div>
      {profileInjectionIndex > 0 && (
        <div className={styles.authorBlockContainer}>
          <AuthorBlock
            data={profileElements}
            profileHeadlineElement={bodyElements[profileInjectionIndex]}
          />
        </div>
      )}
      <div className={cx(styles.bodyContainer, styles.gutter)}>
        <div className={cx(styles.storylineContainer, styles.contactUs)}>
          <Storyline
            bodyMaxWidth={800}
            path="/about-us"
            typeName={STATIC_PAGE}
            excludeAds={true}
            elements={withoutProfiles.slice(profileInjectionIndex)}
          />
        </div>
      </div>
    </PubBaseLayout>
  );
}
