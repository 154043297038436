import React, { ReactElement } from "react";
import styles from "./tagbutton.module.css";

export type TagsButtonProps = {
  children: React.ReactNode;
  href: string;
};

/**
 * TagsButton
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function TagsButton({
  children,
  href,
}: TagsButtonProps): ReactElement {
  return (
    <a className={styles.button} href={href}>
      {children}
    </a>
  );
}
