const promiseBySrc: Record<string, Promise<void> | undefined> = {};

function loadScript(src: string, async: boolean) {
  let promise = promiseBySrc[src];
  if (!promise) {
    promise = promiseBySrc[src] = new Promise<void>((resolve, reject) => {
      const script = document.createElement("script");

      script.src = src;
      script.async = async;

      script.addEventListener("load", () => {
        resolve();
      });
      script.addEventListener("error", reject);

      document.body.appendChild(script);
    });
  }
  return promise;
}

export default loadScript;
