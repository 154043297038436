import { configHome, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import { Ads, LatestArticle, Typography } from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
};

export default function HomeLatestStory({ data }: Props): ReactNode {
  // Add component logic and state here
  return (
    <div className={styles.bodyContainer}>
      <div className={styles.latestArticleContainer}>
        <div className={styles.latestArticleLayout}>
          <div className={styles.latestArticleItemContainer}>
            {data.latestArticle.items.map((teaser, index) => (
              <div
                key={index}
                className={cx(
                  styles.latestArticleItem,
                  configHome.latestArticle.skipImage ||
                    configHome.latestArticle.landscapeIndexes?.includes(index)
                    ? styles.landscape
                    : styles.portrait,
                )}
              >
                <LatestArticle
                  key={index}
                  {...teaser}
                  skipImage={configHome.latestArticle.skipImage}
                  mode={
                    configHome.latestArticle.skipImage ||
                    configHome.latestArticle.landscapeIndexes?.includes(index)
                      ? "landscape"
                      : "portrait"
                  }
                  mobileModeOriginal={
                    configHome.latestArticle.mobileModeOriginal
                  }
                  placeholders={placeholders}
                  displayPublishDate={configTeaser.publishDate}
                />
              </div>
            ))}
          </div>

          {data.latestArticle.viewMore && (
            <div className={styles.latestArticleCtaContainer}>
              <Typography.TextLink href={data.latestArticle.viewMore.url}>
                {configHome.latestArticle.viewMore?.text ||
                  data.latestArticle.viewMore.text}
                {configHome.viewMore?.icon}
              </Typography.TextLink>
            </div>
          )}
        </div>
        <div className={styles.imuContainer}>
          <Ads.AdsSideBar />
        </div>
      </div>
    </div>
  );
}
