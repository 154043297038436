import {
  ResolvedGalleryItemHtmlInfoCUEElement,
  ResolvedGalleryItemImageCUEElement,
} from "@app/layouts/Article/elements";
import styles from "@app/layouts/Article/Storyline.module.css";
import { Typography } from "@sphtech/dmg-design-system";

type GalleryItemTitleProps = {
  info:
    | ResolvedGalleryItemImageCUEElement
    | ResolvedGalleryItemHtmlInfoCUEElement
    | undefined;
};

const GalleryItemTitle = ({ info }: GalleryItemTitleProps) => {
  const text = info?.additionalFields.title?.value;
  if (!text) {
    return null;
  }
  return (
    <Typography.Heading.SubHeading3>
      {info.additionalFields.targetUrl?.value ? (
        <a
          href={info.additionalFields.targetUrl.value}
          className={styles.externalLinks}
          rel="noopener nofollow noreferrer"
          target="_blank"
        >
          {text}
        </a>
      ) : (
        text
      )}
    </Typography.Heading.SubHeading3>
  );
};

export default GalleryItemTitle;
