import { ReactNode, PropsWithChildren } from "react";
import { Divider, Typography } from "../../../main";
import cx from "classnames";

import styles from "./promobox.module.css";

const { Heading } = Typography;
const { SubHeading2, SubHeading4 } = Heading;

export default function PromoBox({
  label,
  title,
  children,
}: PropsWithChildren<{
  label?: string;
  title?: string;
}>): ReactNode {
  return (
    <div className={cx(styles.promoBox, styles.boxShadow)}>
      <div className={styles.heading}>
        <SubHeading4>{label}</SubHeading4>
        <SubHeading2>{title}</SubHeading2>
        <div className={styles.divider}>
          <Divider size="sm" />
        </div>
      </div>

      {children}
    </div>
  );
}
