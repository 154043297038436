import { ResponsiveImage } from "@sphtech/dmg-design-system";
import { Disclaimer } from "packages/design-system/src/components/Element/Typography";

import {
  isCUEImageElement,
  ResolvedColumnImageElement,
  ResolvedCUEElement,
  resolveImageCrop,
} from "../elements";
import styles from "../Storyline.module.css";

const isResolvedColumnInfo = (
  item?: ResolvedCUEElement,
): item is ResolvedColumnImageElement =>
  !!item && item.type === "column_images_item_info";

export function ColumnImage({
  element: { children },
  bodyMaxWidth,
}: {
  element: ResolvedCUEElement;
  bodyMaxWidth: number;
}) {
  return (
    <div className={styles.columnImageContainer}>
      {children?.map((item, index) => {
        if (!item.children) return;
        const image = item.children.find((item) => item.type === "image");
        const crop =
          isCUEImageElement(image) && resolveImageCrop(image, "portrait");
        if (!crop) {
          return null;
        }

        const imageElement = (
          <ResponsiveImage
            key={index}
            src={crop.src}
            alt={crop.alt}
            srcWidth={crop.srcWidth}
            srcHeight={crop.srcHeight}
            displayWidth={bodyMaxWidth / 2}
            loading="lazy"
          />
        );

        const link = item.children.find(
          (item) => item.type === "column_images_item_info",
        );
        const href =
          isResolvedColumnInfo(link) && link.additionalFields.targetUrl?.value;

        const title = isResolvedColumnInfo(link) && (
          <div className={styles.caption}>
            <Disclaimer>{link.additionalFields.title?.value}</Disclaimer>
          </div>
        );

        if (href) {
          return (
            <a
              key={index}
              className={styles.column}
              href={href}
              target="_blank"
              rel="noreferrer"
            >
              {imageElement}
              {title}
            </a>
          );
        }

        return (
          <div key={index} className={styles.column}>
            {imageElement}
            {title}
          </div>
        );
      })}
    </div>
  );
}
