import { configHome } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  AffiliatesProductCarousell,
  ContentBlock,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
};

export default function HomeColumnSideScroll({ data }: Props): ReactNode {
  const filterColumnSideScroll = data.contentBlock.filter(
    (item) => item.type === "columnSideScroll",
  );

  const columnSideScroll =
    filterColumnSideScroll.length > 0 && filterColumnSideScroll[0];

  return (
    columnSideScroll && (
      <div className={cx(styles.columnSideScrollContainer)}>
        <div className={cx(styles.bodyContainer, styles.gutter)}>
          <ContentBlock
            heading={columnSideScroll.title}
            cta={{
              url: columnSideScroll.url,
            }}
            withDivider={configHome.headingDivider}
            isHeadingClickable={configHome.isHeadingClickable}
          >
            <AffiliatesProductCarousell
              itemHeight={300}
              itemWidth={190}
              items={columnSideScroll.items.map((item) => {
                const crop =
                  item.image?.crops.original || placeholders.portrait;
                return {
                  image: { ...crop },
                  link: item.path,
                  title: item.title,
                };
              })}
              noOfItemsInFrame={4}
            />
          </ContentBlock>
        </div>
      </div>
    )
  );
}
