const sphmBaseData = {
  article_keyword: "",
  article_type: "", //Featured article / Events & Promo / Gallery article / Video article
  articleId: "",
  content_author: "",
  content_category: "free",
  content_level_1: "",
  content_level_2: "",
  content_level_3: "",
  content_publication_date: "",
  content_publication_utc: "",
  content_last_updated_utc: "",
  content_type: "",
  cue_articleid: "",
  locale: "en",
  page_name: "",
  pagination: "1",
  video_id: "",
  video_title: "",
  site_name: "",
  site_address: "",
  gtm_container: "",
  ad_account: "",
  ad_section: "",
  sponsored: false,
  sponsor_start: "",
  sponsor_end: "",
};

const articleTypes = {
  OnlineStory: "Article",
  FeatureStory: "Featured article",
  GalleryStory: "Gallery article",
  VideoStory: "Video article",
};

const PageTypesData = {
  category: {
    page_name: "Category",
    section_name: "category",
    content_type: "listing",
    adUnit: "lb1,lb2,imu1,imu2,imu3,catfish,prestitial",
  },
  homepage: {
    page_name: "Home",
    section_name: "homepage",
    content_type: "index",
    adUnit: "lb1,lb2,imu1,imu2,imu3,catfish,prestitial",
  },
  search: {
    page_name: "Search",
    section_name: "search",
    content_type: "internal search",
    adUnit: "lb1,lb2,imu1,imu2,imu3,catfish,prestitial",
  },
  article: {
    page_name: "Article",
    section_name: "article",
    content_type: "article",
    adUnit:
      "lb1,imu1,imu2,imu3,midarticlespecial,midarticlespecial2,catfish,prestitial",
  },
  videos: {
    page_name: "Videos",
    section_name: "videos",
    content_type: "listing",
    adUnit: "lb1,lb2,catfish,prestitial",
  },
  tag: {
    page_name: "Tag",
    section_name: "tag",
    content_type: "listing",
    adUnit: "lb1,lb2,imu1,imu2,imu3,catfish,prestitial",
  },
  latest: {
    page_name: "Latest Stories",
    section_name: "latest-stories",
    content_type: "listing",
    adUnit: "lb1,lb2,imu1,imu2,imu3,catfish,prestitial",
  },
  contenthub: {
    page_name: "Content Hub",
    section_name: "content-hub",
    content_type: "listing",
    adUnit: "lb1,lb2,catfish,prestitial",
  },
  author: {
    page_name: "Author",
    section_name: "author",
    content_type: "internal search",
    adUnit: "lb1,lb2,imu1,imu2,imu3,catfish,prestitial",
  },
};

const sph_data = `
    var _data = {};
    _data = {
      "level2": "1",
      "chapter1": "",
      "chapter2": "",
      "chapter3": "",
      "contenttype": "",
      "pagination": "1",
      "author": "",
      "adblocker": ( window.canRunAds === undefined )? 1 : 0,
      "articlecount": "0",
      "articleid": "",
      "article_source": "",
      "cue_articleid": "",
      "contentcat": "1",
      "story_threads": "",
      "keyword": "",
      "printcat" : "",
      "sph_bs" : "",
      "sph_iab" : "",
      "pubdate" : "",
      "abVariant" : "ST-DEFAULT",
      "title": "ST_Homepage",
      "title_ga": "ST Homepage",
    };
    _data.permutive_id = window.localStorage.getItem("permutive-id") || "";
    _data.ac =  "";
    _data.at = "";
    _data.visitorcat = 1;
    /*if(window.getCookieByName("visitorcat") != undefined && window.getCookieByName("mysphw") != undefined) {
      var visitorcat = window.getCookieByName("visitorcat");
      _data.visitorcat = visitorcat;
      _data.ac =  visitorcat;
      _data.at = window.getCookieByName("mysphw");
    }
    if(window.getCookieByName("svc") != undefined) {
      var svc = window.getCookieByName("svc");
      _data.svc = svc;
    }*/
`;

export { sphmBaseData, articleTypes, sph_data, PageTypesData };
