import { TeaserData } from "../types/ServerCustomContext";
import { buildUrlWithGARefCode } from "./ga/tracking";

const addGaRefCodeToTeaserData = (story: TeaserData, gaRefCode?: string) => ({
  ...story,
  path: buildUrlWithGARefCode(story.path, gaRefCode),
  sections: story.sections.map((section) => ({
    ...section,
    path: section.path && buildUrlWithGARefCode(section.path, gaRefCode),
    parent: section.parent && {
      ...section.parent,
      path:
        section.parent.path &&
        buildUrlWithGARefCode(section.parent.path, gaRefCode),
    },
  })),
});

export const createTeaserMapper =
  ({
    gaRefCode,
    overwrites,
  }: {
    gaRefCode?: string;
    overwrites?: Partial<TeaserData>;
  }) =>
  (story: TeaserData) => ({
    ...addGaRefCodeToTeaserData(story, gaRefCode),
    ...overwrites,
  });

export const mapTeaserDataToAnchor = addGaRefCodeToTeaserData;
export const mapTeaserDataToLatestProps = addGaRefCodeToTeaserData;
