import style from "./image.module.css";

export type ImageProps = {
  src: string;

  alt: string;

  width?: string | number;

  height?: string | number;
  /**
   * Sets the Image [object-fit](https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit) style.
   */
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";

  loading?: "lazy" | "eager";
};

export default function Image({
  src,
  alt,
  width,
  height,
  objectFit,
  loading,
}: ImageProps) {
  return (
    <img
      src={src}
      alt={alt}
      style={{ width, height, objectFit }}
      className={style.image}
      loading={loading}
    />
  );
}
