import PubBaseLayout from "@pub/BaseLayout";
import { configGTM, configSite } from "@pub/config";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import MetaData from "@src/app/components/Modules/MetaData";
import ErrorLayout from "@src/app/layouts/ErrorLayout/ErrorLayout";
import HomeLayout from "@src/app/layouts/Home/HomeLayout";
import { GlobalBaseData, TeaserData } from "@src/app/types/ServerCustomContext";
import AdSettings from "@src/app/utils/AdSettings";
import {
  GA4_REF_CODE_ANCHORBLOCK_A,
  GA4_REF_CODE_LATEST_BLOCKS,
} from "@src/app/utils/ga/ref-code";
import {
  buildUrlWithGARefCode,
  getContentBlockRefCode,
} from "@src/app/utils/ga/tracking";
import { createTeaserMapper } from "@src/app/utils/mapTeaserDataToProps";
import { Helmet } from "react-helmet-async";

export type HomeData = {
  topStories: TeaserData[];
  socialVideo?: {
    title: string;
    type: string;
    url?: string;
    teasers?: {
      embedUri?: string;
    }[];
  };
  blocks: {
    section: string;
    title: string;
    url?: string;
    type: string;
    teasers?: TeaserData[];
    code: string;
    cta?: boolean;
  }[];
};

const pageTitle =
  "Harper's BAZAAR Singapore / Fashion, Beauty, Travel, Parties & Culture";

export default function HomePage() {
  const routeContext: CustomContext<
    TRouteWithRedirect<HomeData & GlobalBaseData, string>
  > = useRouteContext();
  const { context: response } = routeContext;

  if (response.type !== ResponseType.SUCCESS) {
    return <ErrorLayout response={response} />;
  }

  const topStory = response.payload.topStories[0];
  // HBS editorial does not want to show dates on the homepage
  const teaserOverwrites = {
    publishDate: undefined,
    updateDate: undefined,
  };

  return (
    <PubBaseLayout>
      <Helmet>
        <meta
          name="google-site-verification"
          content={configGTM.siteVerification}
        />
      </Helmet>
      <MetaData
        title={`${configSite.name} | ${configSite.tagline}`}
        path="/"
        type="WebPage"
        description={configSite.description}
      />
      <AdSettings
        pageType="homepage"
        sectionName="Homepage"
        adUnits="lb1,lb2,imu1,imu2,imu3,catfish,prestitial"
        pageTitle={pageTitle}
        currentLocation={{
          pathname: "/",
        }}
      />

      <HomeLayout
        data={{
          AnchorBlockProps: createTeaserMapper({
            gaRefCode: GA4_REF_CODE_ANCHORBLOCK_A,
            overwrites: teaserOverwrites,
          })(topStory),
          latestArticle: {
            items: response.payload.topStories.slice(1, 5).map(
              createTeaserMapper({
                gaRefCode: GA4_REF_CODE_LATEST_BLOCKS,
                overwrites: teaserOverwrites,
              }),
            ),
            viewMore: {
              url: buildUrlWithGARefCode(
                "/latest-stories",
                GA4_REF_CODE_LATEST_BLOCKS,
              ),
              text: "VIEW ALL",
            },
          },
          magazineIssues: {
            title: response.payload.magazineIssues?.title || "",
            type: response.payload.magazineIssues?.type || "",
            url: response.payload.magazineIssues?.url || "",
            subscriptionInfo: {
              heading:
                response.payload.magazineIssues?.config?.home?.title || "",
              description:
                response.payload.magazineIssues?.config?.home?.subtitle || "",
              buttonText:
                response.payload.magazineIssues?.config?.home?.buttonText || "",
              buttonUrl:
                response.payload.magazineIssues?.config?.home?.buttonUrl || "",
            },
            items:
              response.payload.magazineIssues?.teasers?.map((issue) => {
                const crop =
                  issue.image?.crops.original || issue.image?.crops.portrait;
                return {
                  url: issue.path || "",
                  image: crop?.src || "",
                  srcWidth: crop?.srcWidth || 0,
                  srcHeight: crop?.srcHeight || 0,
                  alt: issue.image?.alt || "",
                  caption: issue.title || "",
                };
              }) || [],
          },
          socialVideo: response.payload.socialVideo && {
            title: response.payload.socialVideo.title || "",
            type: response.payload.socialVideo.type || "",
            url: response.payload.socialVideo.url || "",
            items:
              response.payload.socialVideo.teasers?.map((teaser) => ({
                embedUrl: teaser.embedUri || "",
              })) || [],
          },
          contentBlock: response.payload.blocks.map((block) => ({
            title: block.title,
            type: block.type,
            uniqSectionName: block.section.startsWith("section-")
              ? block.section.replace("section-", "")
              : undefined,
            url: buildUrlWithGARefCode(
              block.url || "",
              getContentBlockRefCode(block.code),
            ),
            cta: block.cta !== false,
            items: block.teasers
              ? block.teasers.map(
                  createTeaserMapper({
                    gaRefCode: getContentBlockRefCode(block.code),
                    overwrites: teaserOverwrites,
                  }),
                )
              : [],
            withImu: false,
          })),
        }}
      />
    </PubBaseLayout>
  );
}
