import { Typography } from "@sphtech/dmg-design-system";
import { ReactElement } from "react";

import styles from "../contenthub.module.css";

const { Heading } = Typography;

type ContentHubTitleProps = {
  title: string;
};

/**
 * ContentHubTitle
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function ContentHubTitle({
  title,
}: ContentHubTitleProps): ReactElement {
  return (
    <>
      <div className={styles.articleMastheadTextContainer}>
        <div className={styles.articleMastheadTitleWrapper}>
          <div className={styles.articleMastheadTitle}>
            <Heading.SectionTitle>{title}</Heading.SectionTitle>
          </div>
        </div>
      </div>
    </>
  );
}
