import { configHeader, configSite } from "@pub/config";

import { getAuthors } from "./mappers";
import { MetaDataProps } from "./types";

type JSONLDProps = {
  path: string;
  canonicalUrl: string;
  metaData: MetaDataProps;
};

export default function generateJsonLd({
  path,
  metaData,
  canonicalUrl,
}: JSONLDProps) {
  const { type } = metaData;

  const primaryImage = metaData.ogFields?.image || metaData.image;

  const Base = {
    "@type": type === "CollectionPage" ? metaData.type : "WebPage",
    "@id": canonicalUrl,
    url: canonicalUrl,
    isPartOf: {
      "@id": `${configSite.address}/#website`,
    },
    description: metaData.description,
    breadcrumb: {
      "@id": `${canonicalUrl}/#breadcrumb`,
    },
    ...(canonicalUrl === configSite.address + "/" && {
      about: {
        "@id": `${canonicalUrl}#organization`,
      },
    }),
    thumbnailUrl: primaryImage?.src,
    potentialAction:
      type !== "CollectionPage"
        ? [
            {
              "@type": "ReadAction",
              target: [canonicalUrl],
            },
          ]
        : undefined,
    inLanguage: "en-SG",
  };

  const Organization = {
    "@type": "Organization",
    "@id": `${configSite.address}/#organization`,
    name: "SPH Media Limited",
    url: `${configSite.address}/`,
    logo: {
      "@type": "ImageObject",
      inLanguage: Base.inLanguage,
      "@id": `${configSite.address}/#/schema/logo/image/`,
      url: `${configSite.address}${configSite.logoPath}`,
      contentUrl: `${configSite.address}${configSite.logoPath}`,
      caption: "SPH Media Limited",
    },
    image: {
      "@id": `${configSite.address}/#/schema/logo/image/`,
    },
    sameAs: configHeader.socialMedia.icons.map((icon) => icon.href),
  };

  const Website = {
    "@type": "WebSite",
    "@id": Base.isPartOf["@id"],
    url: `${configSite.address}/`,
    name: configSite.name,
    description: configSite.description,
    publisher: {
      "@id": Organization["@id"],
    },
    potentialAction: [
      {
        "@type": "SearchAction",
        target: {
          "@type": "EntryPoint",
          urlTemplate: `${configSite.address}/search?q={search_term_string}`,
        },
        "query-input": "required name=search_term_string",
      },
    ],
    inLanguage: Base.inLanguage,
  };

  const ImageObject =
    primaryImage && (type === "Article" || type === "CollectionPage")
      ? {
          "@type": "ImageObject",
          inLanguage: Base.inLanguage,
          "@id": `${canonicalUrl}/#primaryimage`,
          url: primaryImage.src,
          contentUrl: primaryImage.src,
          width: primaryImage.srcWidth,
          height: primaryImage.srcHeight,
          caption: primaryImage.alt,
          description: primaryImage.alt,
          creditText: primaryImage.credit,
        }
      : undefined;

  const WebPage = {
    ...Base,
    ...((type === "CollectionPage" || type === "Article") && {
      name: metaData.title,
      ...(ImageObject && {
        image: {
          "@id": ImageObject["@id"],
        },
        primaryImageOfPage: {
          "@id": ImageObject["@id"],
        },
      }),
    }),
  };

  const breadcrumbList = [
    {
      name: "Home",
      item: "/",
    },
    ...(metaData.breadcrumb || []),
  ];
  // add current page to breadcrumb unless home
  if (path !== "/") {
    breadcrumbList.push({
      name: metaData.title,
      item: path,
    });
  }
  const BreadCrumb = {
    "@type": "BreadcrumbList",
    "@id": Base.breadcrumb["@id"],
    itemListElement: breadcrumbList.map(({ name, item }, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name,
      item: item.startsWith("/") ? `${configSite.address}${item}` : item,
    })),
  };

  const Article = type === "Article" && {
    ...Base,
    "@type": "Article",
    isPartOf: {
      "@id": canonicalUrl,
    },
    headline: metaData.title,
    mainEntityOfPage: {
      "@id": canonicalUrl,
    },
    author: getAuthors(metaData.storyData).map((author) => ({
      name: author.text,
      url: author.href && `${configSite.address}${author.href}`,
    })),
    wordCount: metaData.storyData.processedElements.bodyElements
      .filter((element) => element.type === "paragraph")
      .reduce(
        (acc, element) =>
          acc + (element.value ? element.value.split(" ").length : 0),
        0,
      ),
    publisher: {
      "@id": Organization["@id"],
    },
    image: ImageObject && {
      "@id": ImageObject["@id"],
    },
    keywords: metaData.storyData.tags.map((tags) => tags.name),
    articleSection: metaData.storyData.sections.map(
      (sections) => sections.name,
    ),
    datePublished: metaData.storyData.publishDate,
    dateModified: metaData.storyData.updateDate,
  };

  return {
    "@context": "https://schema.org",
    "@graph": [
      WebPage,
      Article,
      BreadCrumb,
      ImageObject,
      Website,
      Organization,
    ].filter(Boolean),
  };
}
