import { TeaserData } from "@app/types/ServerCustomContext";
import { configLatestStory, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import { LatestArticle } from "@sphtech/dmg-design-system";
import cx from "classnames";

import styles from "./subanchor.module.css";

type SubAnchorProps = {
  articleList: TeaserData[] | undefined;
};

const SubAnchor = ({ articleList }: SubAnchorProps) => {
  if (articleList && articleList.length > 0) {
    return articleList.map((teaser, index: number) => (
      <div
        key={index}
        className={cx(
          styles.subAnchor,
          configLatestStory.landscapeIndexes.includes(index)
            ? styles.landscape
            : styles.portrait,
        )}
      >
        <LatestArticle
          key={index}
          {...teaser}
          mode={
            configLatestStory.landscapeIndexes.includes(index)
              ? "landscape"
              : "portrait"
          }
          portraitImageWidth={configLatestStory.portraitImageWidth}
          landscapeImageWidth={configLatestStory.landscapeImageWidth}
          placeholders={placeholders}
          displayPublishDate={configTeaser.publishDate}
        />
      </div>
    ));
  }
};

export default SubAnchor;
