import { CAAS_INDEX_SUFFIX, DISABLE_ADS } from "@src/app/constants";
import {
  GA4_REF_CODE_CONTENTBLOCK_B,
  GA4_REF_CODE_CONTENTBLOCK_F,
  GA4_REF_CODE_CONTENTBLOCK_H,
} from "@src/app/utils/ga/ref-code";
import {
  BRIGHTCOVE_CONFIG,
  DEFAULT_CONFIG_GAM,
  LOTAME_CONFIG,
  PREMUTIVE_CONFIG,
} from "@src/pubs/common/config";

import {
  ConfigAboutUs,
  ConfigAffiliatesDeclaration,
  ConfigAuthorProfile,
  ConfigBrightcove,
  ConfigCaas,
  ConfigCategory,
  ConfigContentHub,
  ConfigError,
  ConfigExploreMore,
  ConfigGAM,
  ConfigGTM,
  ConfigHome,
  ConfigLatestStory,
  ConfigLotame,
  ConfigNewsletter,
  ConfigPermutive,
  ConfigSearch,
  ConfigSingleStory,
  ConfigSite,
  ConfigSkimlinks,
  ConfigTag,
  ConfigTeaser,
  ConfigTrackingCode,
  ConfigVideoLanding,
} from "../types/config";
import logoPath from "./logo.svg?url";
import newsletterBg from "./newsletterbg.png";

export const configSite: ConfigSite = {
  name: "PEAK Singapore",
  address:
    import.meta.env.VITE_PUBLIC_BASE_URL ||
    "https://www.thepeakmagazine.com.sg/", // without trailing slash
  tagline:
    "thepeakmagazine.com.sg is a business-lifestyle publication rooted in meaningfulness, curating stories that inspire leaders and visionaries to lead impactful lives.",
  description:
    "The Peak is a business-lifestyle publication rooted in meaningfulness, curating stories that inspire leaders and visionaries to lead impactful lives.",
  titleSeparator: "—",
  logoPath,
  pageShare: {
    size: 36,
    fill: "#4A5544",
    media: [
      {
        name: "Facebook",
      },
      {
        name: "LinkedIn",
      },
      {
        name: "Telegram",
      },
      {
        name: "Whatsapp",
      },
      {
        name: "Email",
      },
    ],
  },
};

export { configHeader } from "./config/configHeader";

export const configCaas: ConfigCaas = {
  index: `thepeak${CAAS_INDEX_SUFFIX}`,
  tagIdPrefix: "tag:thepeak-keywords@sph.com.sg,2023:",
  cuePublicationName: "thepeak",
};

export const configGTM: ConfigGTM = {
  container: "GTM-MRJP6VP", //Peak
  siteVerification: "_cGEdj2ZMeQhb_OTIAKvlCGbSxaBwct6M6XrDxywcXM",
};

export const configGAM: ConfigGAM = {
  ...DEFAULT_CONFIG_GAM,
  account: "/5908/peak-sg",
};

export const configLotame: ConfigLotame = {
  ...LOTAME_CONFIG,
  active: !DISABLE_ADS,
};

export const configPermutive: ConfigPermutive = {
  ...PREMUTIVE_CONFIG,
  active: !DISABLE_ADS,
};

export const configSkimlinks: ConfigSkimlinks = {
  active: !DISABLE_ADS,
  skimlinkId: "157725X1632720",
  geniuslinkId: "319906",
};

export const configBrightcove: ConfigBrightcove = {
  ...BRIGHTCOVE_CONFIG,
};

export const configSingleStory: ConfigSingleStory = {
  byline: {
    defaultCredit: "The Peak Team",
    separator: "/",
  },
  authorProfile: true,
  configShareArticle: {
    label: "Share this article",
    divider: {
      top: false,
    },
  },
  topDivider: false,
  gallery: {
    paginationSeparator: "/",
  },
  featureStory: {
    bodyMaxWidth: 890,
  },
  displayTags: {
    displayTopTags: true,
    displayTagHeading: false,
  },
};

export const configLatestStory: ConfigLatestStory = {
  latestStoryLimit: 21,
  subAnchorLimit: 3,
  landscapeIndexes: [0, 1, 2],
  mainContentBlockDisplay: "grid",
  mainContentBlockMode: "landscape",
  errorLayoutMode: "landscape",
  portraitImageWidth: 285,
  landscapeImageWidth: 345,
};

export const configCategory: ConfigCategory = {
  pinnedTeasersLimit: 3,
  latestStoriesLimit: 8,
  adPositioning: 3,
  videoGalleryLandscape: {
    mobileNavigation: true,
    display: true,
  },
  dividerVariant: "secondary",
  showVerticalLine: true,
};

export const configContentHub: ConfigContentHub = {
  configMasthead: {
    heading: {
      display: true,
    },
    gradientOverlay: {
      display: true,
    },
  },
  configBodyContent: {
    display: true,
  },
  configShareButton: {
    display: false,
  },
  configEditorsNote: {
    display: false,
  },
  configCallQoute: {
    display: true,
  },
  configFeaturedSection: {
    display: true,
  },
  configDeals: {
    display: true,
    props: {
      heading: "editor's picks",
      noOfItemsInFrame: 4,
    },
  },
  configTopStories: {
    display: true,
    listingPerPage: 6,
  },
  storiesPerPage: {
    topStories: 4,
    numberOfStories: 10,
  },
  configListingStories: {
    mode: "landscape",
  },
  contenthubBlockF: {
    mode: "landscape",
  },
  layout: {
    stories: {
      elements: [
        { name: "contentBlockB", limit: 4 },
        { name: "adsMidContent" },
      ],
    },
  },
};

export const configTag: ConfigTag = {
  topStoriesLimit: 4,
  listedStoriesLimit: 12,
  itemSeparator: ", ",
  subHeading: "Tag",
  showVerticalLine: true,
};

export const configAmazonAffiliates = {};

export const configSearch: ConfigSearch = {
  loadMoreText: "Loading more results",
};

export const configExploreMore: ConfigExploreMore = {
  heading: "Explore other topics",
};

export const configVideoLanding: ConfigVideoLanding = {};

export const configHome: ConfigHome = {
  anchorBlock: {
    hideBreadCrumb: false,
    hideVerticalLine: true,
  },
  isHeadingClickable: true,
  headingDivider: false,
  viewMore: { icon: false },
  latestArticle: {
    mobileModeOriginal: false,
    landscapeIndexes: [0, 1, 2, 3],
    viewMore: {
      text: "VIEW ALL",
    },
  },
  layoutGrid: {
    elements: [
      { name: "anchorBlock" },
      { name: "latestArticle" },
      { name: "contentBlockJ", alternateBg: true, trendingBlock: false },
      { name: "contentBlockF", alternateBg: false },
      { name: "contentBlockK" },
      { name: "adUnit" },
      { name: "contentBlockL" },
      { name: "contentBlockF", dataIndex: 1 },
      {
        name: "contentBlockJ",
        alternateBg: true,
        dataIndex: 0,
        trendingBlock: true,
      },
      { name: "socialVideoPortrait" },
      { name: "magazineListing" },
      { name: "newsletter" },
    ],
  },
  magazineListing: {
    midDivider: true,
  },
  newsletter: {
    containerImageBg: newsletterBg,
    newsletterTitle:
      "Enjoy meaningful reads on thought leadership and luxury lifestyle for today's leaders and visionaries.",
    newsletterBody: "Sign up for our weekly newsletter.",
  },
  contentHubStories: 4,
  contentBlockF: {
    section: ["people", "influence"],
    limit: 4,
    landscapeIndexes: [0, 1, 2, 3],
    layoutTwoColumn: [1, 2, 3],
    anchorBlock: {
      index: 0,
      mediaScreen: "all-screen",
      hideVerticalLine: false,
    },
    landscapeImageWidth: 295,
    twoColumnsLandscapeImageWidth: 300,
  },
  contentBlockL: {
    section: "lifestyle",
    landscapeIndexes: [0, 1, 2, 3, 4],
    anchorBlock: {
      index: 0,
      mediaScreen: "all-screen",
      hideVerticalLine: false,
    },
    landscapeImageWidth: 295,
  },
  contentBlockK: {
    section: "watch-jewellery",
    skipSectionContext: true,
  },
  socialVideoLimit: 3,
  trending: {
    limit: 4,
  },
  trendingStoriesPublisher: "thepeak",
};

export const configNewsletter: ConfigNewsletter = {
  image: newsletterBg,
  title:
    "Enjoy meaningful reads on thought leadership and luxury lifestyle for today's leaders and visionaries.",
  body: "Sign up for our weekly newsletter.",
};

export const configTeaser: ConfigTeaser = {
  publishDate: false,
  anchorBlock: {
    mobileCrop: "landscape",
  },
};

export const configTrackingCode: ConfigTrackingCode = {
  home: {
    topic: ["contenthub"],
    trending: "trending",
    blockk: "block-d",
    blockl: "block-b",
    blockf: ["block-c", "contentblock"],
    blockd: "howto",
  },
  category: {
    mainContentBlock: GA4_REF_CODE_CONTENTBLOCK_H,
  },
  exploremore: "exploremore",
  contenthub: {
    topstories: {
      contentBlockB: GA4_REF_CODE_CONTENTBLOCK_B,
      contentBlockF: GA4_REF_CODE_CONTENTBLOCK_F,
    },
  },
  customSection: ["hometours", "howto"],
};

export const configAffiliatesDeclaration: ConfigAffiliatesDeclaration = {
  text: "This article contains affiliate links. If you buy through these links, we may earn a small commission.",
};

export const configAboutUs: ConfigAboutUs = {
  profileBlock: {
    heading: "Meet The Team",
  },
};

export const configError: ConfigError = {
  relatedTitle: "Perhaps what you are looking for is here.",
};

export const configAuthorProfile: ConfigAuthorProfile = {
  sectionHeadingText: "Articles by",
};
