import cx from "classnames";
import { CSSProperties, useEffect, useState } from "react";
import loadScript from "../../Utility/LoadScript";
import { makeSvgDataSrc } from "../../Utility/placeholder";
import TwitterTweetEmbed from "../TwitterEmbed/TwitterTweetEmbed";
import styles from "./index.module.css";
import {
  convertToEmbed,
  embedScriptRecord,
  getAspectRatio,
  setupResizeListener,
  SOCIAL_MEDIA,
} from "./utils";
import YouTubeFacade from "./YouTubeFacade";

/**
 * We listen for resize event outside of the react lifecycle
 * - no need for additional state and ref, more similar to how 3rd party scripts do it
 */
setupResizeListener();

const ResponsiveIframe: React.FC<{
  /**
   * The url of the iframe. It can automatically convert common video urls into embed urls.
   * It will also guess the aspect ratio based off this unless srcWidth and srcHeight is given.
   */
  src: string;
  /** iframe title attribute */
  title?: string;
  /** Loading for browser native lazy loading. Specify "eager" for YouTube videos that do not require facade. */
  loading?: "lazy" | "eager";
  /** Allow for amending embed code path for certain hostnames */
  embedPathAmends?: Record<string, string>;
  /** Set a min height which also enables scrolling and skipps aspect ratio */
  minHeight?: number;
  /** Allow for forcing aspect ratio on certain components (e.g. VideoGalleryLandscape) */
  forceAspectRatio?: number;
  mobileUp?: {
    forceAspectRatio?: number;
  };
  paddingBottom?: string;
}> = ({
  src,
  title,
  loading,
  embedPathAmends,
  minHeight,
  forceAspectRatio,
  mobileUp,
  paddingBottom,
}) => {
  const [embedSrc, social] = convertToEmbed(src, embedPathAmends, loading);
  const [showYTFacade, setShowYTFacade] = useState(
    social === SOCIAL_MEDIA.YOUTUBE && loading !== "eager",
  );
  const resovledCustomScript = embedScriptRecord[social];
  const [loadingEmbedScript, setLoadingEmbedScript] =
    useState(!!resovledCustomScript);

  useEffect(() => {
    if (resovledCustomScript) {
      loadScript(resovledCustomScript.script, true)
        .then(() => {
          if (resovledCustomScript.callback) {
            resovledCustomScript.callback();
          }
          setLoadingEmbedScript(false);
          return;
        })
        .catch((e) => {
          setLoadingEmbedScript(false);
          console.error(e);
        });
    }
  }, [resovledCustomScript]);

  if (import.meta.env.VITE_DISABLE_EMBEDS) {
    return (
      <img
        src={makeSvgDataSrc({
          height: 100,
          width: getAspectRatio(src, social) * 100,
          text: "DISABLE_EMBEDS",
        })}
        className={cx(styles.base, styles[social])}
      />
    );
  }

  if (social === SOCIAL_MEDIA.TWITTER) {
    return <TwitterTweetEmbed tweetId={embedSrc.split("/").pop()} />;
  }

  if (social === SOCIAL_MEDIA.FACEBOOK) {
    return (
      <div
        className={cx(
          styles.facebookContainer,
          loadingEmbedScript && styles.loadingEmbedScript,
        )}
      >
        <div className="fb-post" data-href={src} data-show-text="false" />
      </div>
    );
  }

  if (showYTFacade) {
    return (
      <YouTubeFacade
        src={src}
        title={title}
        onClick={() => setShowYTFacade(false)}
        aspectRatio={
          forceAspectRatio ?? getAspectRatio(src, SOCIAL_MEDIA.YOUTUBE)
        }
      />
    );
  }

  const hasResponsiveAspectRatio = !!mobileUp?.forceAspectRatio && !minHeight;
  const style: CSSProperties & {
    "--custom-aspect-ratio"?: number;
    "--custom-mobile-up-aspect-ratio"?: number;
  } = {
    paddingBottom,
  };
  if (minHeight) {
    style.minHeight = `${minHeight}px`;
  } else {
    if (hasResponsiveAspectRatio) {
      style["--custom-aspect-ratio"] = forceAspectRatio;
      style["--custom-mobile-up-aspect-ratio"] = mobileUp?.forceAspectRatio;
    } else {
      style.aspectRatio = forceAspectRatio ?? getAspectRatio(src, social);
    }
  }

  return (
    <iframe
      className={cx(
        styles.base,
        styles[social],
        hasResponsiveAspectRatio && styles.responsiveAspectRatio,
      )}
      title={title}
      // Yes this is deprecated, BUT there is no straightforward solution to hide the scrollbar
      // - limit to instagram where we get height events from iframe
      scrolling={social === SOCIAL_MEDIA.INSTAGRAM ? "no" : undefined}
      src={embedSrc}
      style={style}
      allowFullScreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
      loading={loading}
    />
  );
};

export default ResponsiveIframe;
