import { ResolvedFieldValue } from "@app/layouts/Article/elements";
import styles from "@app/layouts/Article/Storyline.module.css";
import { Annotation } from "@app/layouts/Article/StorylineElements/Annotation/Annotation";
import { Typography } from "@sphtech/dmg-design-system";

type EmbeddedGalleryDescriptionProps = {
  description: ResolvedFieldValue;
};

const EmbeddedGalleryDescription = ({
  description,
}: EmbeddedGalleryDescriptionProps) => {
  return (
    <div className={styles.embedDescription}>
      <Typography.Body>
        <Annotation element={description} />
      </Typography.Body>
    </div>
  );
};

export default EmbeddedGalleryDescription;
