import {
  ResolvedButtonElement,
  ResolvedCUEElement,
} from "@app/layouts/Article/elements";
import styles from "@app/layouts/Article/Storyline.module.css";
import { Button } from "@sphtech/dmg-design-system";

type AppendedGalleryItemProps = {
  galleryItem: ResolvedCUEElement;
};

/**
 * Logic copied from Gallery.tsx L112-L135
 */
const AppendedGalleryItem = ({ galleryItem }: AppendedGalleryItemProps) => {
  if (
    galleryItem.type === "call_to_action" ||
    galleryItem.type === "shop_button"
  ) {
    const cta =
      galleryItem.type === "call_to_action" && galleryItem.children
        ? (galleryItem.children[0] as ResolvedButtonElement)
        : galleryItem;

    const addtionalFields = cta.additionalFields;

    return (
      <span className={styles.buttonInlineWrapper}>
        <Button
          variant="secondary"
          href={
            addtionalFields.destinationUrl?.value ??
            addtionalFields.websiteLink?.value
          }
          target="_blank"
        >
          {addtionalFields.textToDisplay?.value ?? addtionalFields.name?.value}
        </Button>
      </span>
    );
  }

  return null;
};

export default AppendedGalleryItem;
