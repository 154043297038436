import { configContentHub, configHome, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import { LatestArticle } from "@sphtech/dmg-design-system";
import { ContentHubData } from "@src/app/types/ServerCustomContext";
import cx from "classnames";

import styles from "../contenthub.module.css";

type Props = {
  data: Omit<ContentHubData, "metaData">;
  firstItemAnchorBlock?: boolean;
  from?: number;
  limit?: number;
};

export default function ContentHubBlockF({
  data,
  firstItemAnchorBlock = false,
  from = 0,
  limit = 4,
}: Props) {
  const topStories = data.topStories && data.topStories.slice(from, limit);
  if (!topStories?.length) {
    return null;
  }
  return (
    <section className={styles.contentHubBlockF}>
      <div
        className={cx(
          styles.bodyContainer,
          styles.secondaryContentContainer,
          styles.gutterHorizontal,
        )}
      >
        <div className={styles.primaryContentBlockContainer}>
          <div className={styles.primaryContentBlockLayout}>
            {topStories.map((teaser, index: number) => (
              <div
                key={index}
                className={cx(
                  firstItemAnchorBlock
                    ? styles.primaryContentBlockItem
                    : styles.item,
                )}
              >
                <LatestArticle
                  key={index}
                  {...teaser}
                  mode={
                    configContentHub.contenthubBlockF?.mode ||
                    (firstItemAnchorBlock && index === 0)
                      ? "landscape"
                      : "portrait"
                  }
                  mobileModeOriginal={
                    configHome.latestArticle.mobileModeOriginal
                  }
                  placeholders={placeholders}
                  displayPublishDate={configTeaser.publishDate}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
