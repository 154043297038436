import React, { ReactElement } from "react";
import styles from "./label.module.css";

export type Element = "div" | "span"; // Modify this as needed
export type LabelProps = {
  Element?: Element;
  children?: React.ReactNode;
};

function Label({ Element = "div", children }: LabelProps): ReactElement {
  return <Element className={styles.label}>{children}</Element>;
}

export default Label;
