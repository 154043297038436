import React, { ReactElement } from "react";
import styles from "./pagination.module.css";
import cx from "classnames";

type PaginationSize = "small" | "large";
type PaginationProps = {
  children?: React.ReactNode;
  size: PaginationSize;
};

function Pagination(props: PaginationProps): ReactElement {
  const { children, size = "small" } = props;

  return (
    <span className={cx(styles.pagination, styles[size])}>{children}</span>
  );
}

export default Pagination;
