import { useEffect, useState } from "react";

type InViewProps = {
  triggerOnce?: boolean;
  threshold?: number;
};

type State = {
  inView: boolean;
  entry?: IntersectionObserverEntry;
};

function useInView({ triggerOnce, threshold = 0 }: InViewProps) {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [state, setState] = useState<State>({
    inView: false,
    entry: undefined,
  });

  useEffect(() => {
    if (!ref) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];

        if (
          entry.isIntersecting ||
          // threshold 0 can be used to be notified at 1px going into viewport for IntersectionObserver options but intersectionRatio still need to be greater than 0 to be considered in view
          (entry.intersectionRatio > 0 && entry.intersectionRatio >= threshold)
        ) {
          setState({
            inView: true,
            entry,
          });
          if (triggerOnce && entry.isIntersecting) observer.unobserve(ref);
        } else {
          setState({
            inView: false,
            entry,
          });
        }
      },
      { threshold },
    );

    observer.observe(ref);

    return () => {
      observer.unobserve(ref);
    };
  }, [threshold, ref, triggerOnce]);

  return { ref: setRef, inView: state.inView };
}

export { useInView };
