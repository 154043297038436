import { Helmet } from "react-helmet-async";

export default AnalyticsGrapeshot;

function AnalyticsGrapeshot({
  grapeshots,
}: {
  grapeshots: {
    channels: string[];
    fr: string;
  };
}) {
  return (
    <Helmet>
      <meta
        id="grapeshot"
        name="grapeshot_tag"
        content={grapeshots.channels.join(",")}
      />
      <script type="text/javascript">
        {`window.gpt_grapeshots = ${JSON.stringify(grapeshots.channels)};window.gpt_grapeshots_fr = ${JSON.stringify(grapeshots.fr)};`}
      </script>
    </Helmet>
  );
}
