import { configHome } from "@pub/config";
import { VideoGalleryPortrait } from "@sphtech/dmg-design-system";
import {
  whiteArrowLeft,
  whiteArrowRight,
} from "@src/app/theme/icons/commonIcons";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
};
// Desc: Social Video/Embed | Video Gallery (Portrait)
export default function HomeSocialVideoPortrait({ data }: Props): ReactNode {
  return (
    !!data.socialVideo?.items.length && (
      <div className={cx(styles.socialVideoContainer)}>
        <div className={cx(styles.bodyContainer)}>
          <div className={styles.vidoeGalleryPortraitContainer}>
            <VideoGalleryPortrait
              title={data.socialVideo.title}
              items={data.socialVideo.items.map((item) => ({
                caption: "",
                videoUrl: item.embedUrl,
              }))}
              leftIcon={whiteArrowLeft}
              rightIcon={whiteArrowRight}
              imgWidth="250px"
              imgHeight="450px"
              noOfItemsInView={3}
              description=""
              withDivider={configHome.headingDivider}
            />
          </div>
        </div>
      </div>
    )
  );
}
