import { configHome } from "@pub/config";
import { ContentBlock } from "@sphtech/dmg-design-system";
import ProductDeals from "@src/app/components/Modules/Product/ProductDeals";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
};

// Product Deals | Affiliate Products
export default function HomeAffiliateProduct({ data }: Props): ReactNode {
  const filterAffiliateProductBlock = data.contentBlock.filter(
    (item) => item.type === "deals",
  );

  const affiliateProduct =
    filterAffiliateProductBlock.length > 0 && filterAffiliateProductBlock[0];

  return (
    affiliateProduct &&
    affiliateProduct.items.length > 0 && (
      <div className={cx(styles.affiliateProductContainer)}>
        <div className={cx(styles.bodyContainer, styles.gutter)}>
          <ContentBlock
            heading={affiliateProduct.title || "editor’s picks"}
            withDivider={configHome.headingDivider}
            description={configHome.affiliateProduct?.description}
          >
            <ProductDeals data={{ items: affiliateProduct.items }} />
          </ContentBlock>
        </div>
      </div>
    )
  );
}
