import { ResponseType } from "@sphtech/web2-core/ssr";
import ErrorLayout from "@src/app/layouts/ErrorLayout/ErrorLayout";

export default function Fallback() {
  return (
    <ErrorLayout
      response={{
        type: ResponseType.CLIENT_ERROR,
        statusCode: 404,
      }}
    />
  );
}
