import { configGTM } from "@pub/config";
import { Helmet } from "react-helmet-async";

export default AnalyticsGTM;

function AnalyticsGTM() {
  const gtm_config = {
    gtm_container: configGTM.container,
  };

  return (
    <Helmet>
      <script>{`var sphm_gtm_data = ${JSON.stringify(gtm_config)}`}</script>
    </Helmet>
  );
}
