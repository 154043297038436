import { ReactElement, useEffect, useRef, useState } from "react";
import styles from "./twitterttweetembed.module.css";
import cx from "classnames";
import loadScript from "../../Utility/LoadScript";

/** https://developer.x.com/en/docs/twitter-for-websites/embedded-tweets/guides/embedded-tweet-parameter-reference */
type TwitterTweetEmbedOptions = {
  id?: number;
  cards?: "hidden";
  conversation?: "none";
  theme?: "dark";
  /** The maximum width of the rendered Tweet in whole pixels. This value should be between 250 and 550 pixels. */
  width?: number;
  /** Float the Tweet left, right, or center relative to its container. Typically set to allow text or other content to wrap around the Tweet. */
  align?: "left" | "right" | "center";
  lang?: string;
  dnt?: boolean;
};

declare global {
  interface Window {
    twttr: {
      widgets: {
        /** https://developer.x.com/en/docs/twitter-for-websites/embedded-tweets/guides/embedded-tweet-javascript-factory-function */
        createTweet: (
          tweetID: string,
          targetEl: HTMLElement,
          options?: TwitterTweetEmbedOptions,
        ) => Promise<void>;
      };
    };
  }
}

type TwitterTweetEmbedProps = {
  /**
   * Tweet id that needs to be shown
   */
  tweetId?: string;
  options?: TwitterTweetEmbedOptions;
};

export default function TwitterTweetEmbed({
  tweetId,
  options,
}: TwitterTweetEmbedProps): ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);

  const stableOptions = options && JSON.stringify(options);
  useEffect(() => {
    if (!tweetId) {
      return;
    }

    // need to be able to abort, leads to double render with strict mode otherwise
    let isAborted: boolean;
    // Load twitter widget library
    // - keeping twitter.com here, platform.x.com redirects to platform.twitter.com as of July 4th 2024
    loadScript("https://platform.twitter.com/widgets.js", true)
      .then(() => {
        if (!ref.current) {
          return;
        }
        if (isAborted) {
          return;
        }
        if (ref.current.childElementCount) {
          // clear children if re-rendering with e.g. different options
          ref.current.replaceChildren();
          setLoading(true);
        }

        // Request to create tweet from twitter widget plugin
        window.twttr.widgets
          .createTweet(
            tweetId,
            ref.current,
            stableOptions && JSON.parse(stableOptions),
          )
          .then(() => {
            setLoading(false);
          });

        return true;
      })
      .catch((e) => console.error("TwitterEmbed", e));
    return () => {
      isAborted = true;
    };
  }, [tweetId, stableOptions]);

  return (
    <div ref={ref} className={cx(styles.base, loading && styles.loading)} />
  );
}
