import { ResponsiveIframe, Typography } from "@sphtech/dmg-design-system";

import {
  ResolvedEmbedCUEElement,
  ResolvedGalleryItemHtmlInfoCUEElement,
} from "../elements";
import styles from "../Storyline.module.css";
import { Annotation } from "./Annotation/Annotation";

const { Body } = Typography;

const CustomHtml = ({
  htmlInfo,
  element,
}: {
  htmlInfo?: ResolvedGalleryItemHtmlInfoCUEElement;
  element?: ResolvedEmbedCUEElement;
}) => {
  if (!element) return null;

  const url = element.additionalFields.uri?.value?.replace(/"/g, "") || "";
  const { width, height } = element.additionalFields;

  return (
    <div
      className={styles.embedContainer}
      style={{
        maxWidth: width?.value ? `${width.value}px` : undefined,
      }}
    >
      <ResponsiveIframe
        title={htmlInfo?.additionalFields.description?.value ?? ""}
        src={url}
        minHeight={height?.value ? +height.value : undefined}
        embedPathAmends={{
          "www.instagram.com": "captioned/",
        }}
        loading="lazy"
      />
      {htmlInfo?.additionalFields.description?.value && (
        <div className={styles.embedDescription}>
          <Body>
            <Annotation element={htmlInfo.additionalFields.description} />
          </Body>
        </div>
      )}
    </div>
  );
};

export default CustomHtml;
