import { TYPE_BRANDED_CONTENT } from "../constants";
import { StoryData } from "../types/ServerCustomContext";

export const isSponsoredStory = (
  sponsorData?: StoryData["sponsorship"],
  typeName?: string,
  checkDate: Date = new Date(),
) => {
  // Branded content will always sponsored no matter what
  // based on https://sph.atlassian.net/browse/DMG-3342?focusedCommentId=344605
  if (isBrandedContent(typeName)) {
    return true;
  }

  const currentDate = checkDate.toISOString();
  const sponsorStartOk =
    sponsorData?.sponsorCheckbox &&
    (!sponsorData.sponsorDateStart ||
      sponsorData.sponsorDateStart < currentDate);
  const sponsorEndOk =
    sponsorData?.sponsorCheckbox &&
    (!sponsorData.sponsorDateEnd || sponsorData.sponsorDateEnd > currentDate);

  return !!sponsorStartOk && !!sponsorEndOk;
};

export const getSponsorName = (sponsorData?: StoryData["sponsorship"]) => {
  return sponsorData?.sponsorName;
};

export const isBrandedContent = (typeName?: string) => {
  // Forced to return boolean
  return typeName === TYPE_BRANDED_CONTENT ? true : false;
};
