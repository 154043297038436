import cx from "classnames";
import { SummaryItemSmall } from "../../Block/SummaryItem/SummaryItem";
import BreadCrumbs from "../../Element/BreadCrumb";
import ResponsiveIframe from "../../Element/ResponsiveIframe";
import { Pagination } from "../../Element/Typography";
import { datetimeFormatter } from "../../Element/Typography/DateTime/utils";
import { ItemProps } from "./VideoGalleryLandscape";
import styles from "./videogallerylandscape.module.css";

type VideoGalleryItemProps = {
  item: ItemProps;
  index: number;
  position: number;
  itemsLength: number;
  hasPagination: boolean;
  hasSummaryItemPublishedDate: boolean;
};

const VideoGalleryItem = ({
  item,
  index,
  position,
  itemsLength,
  hasPagination,
  hasSummaryItemPublishedDate,
}: VideoGalleryItemProps) => {
  return (
    <div className={styles.spotlightItemContainer}>
      {Math.abs(position - index) <= 1 && (
        <div className={styles.spotlightImageContainer}>
          <ResponsiveIframe
            // Automatically gives a box placeholder if video is not found
            src={item.videoUrl || ""}
            loading="lazy"
            forceAspectRatio={16 / 9}
          />
        </div>
      )}
      <div className={styles.spotlightSummaryContainer}>
        <div className={styles.labelContainer}>
          {hasPagination && (
            <Pagination size="small">
              {index + 1}/{itemsLength}
            </Pagination>
          )}

          <BreadCrumbs
            sections={item.sections ?? []}
            dateElement={
              item.publishedDate
                ? datetimeFormatter(
                    new Date(item.publishedDate),
                    "dd mname yyyy",
                  )
                : undefined
            }
          />
        </div>

        <div className={styles.captionContainer}>
          <SummaryItemSmall
            url={item.captionUrl ?? "/"}
            title={item.caption}
            publishedDate={
              hasSummaryItemPublishedDate ? item.publishedDate : undefined
            }
            dateFormat="dd mname yyyy"
          />
        </div>

        {item?.description && (
          <p className={cx(styles.itemDescription, styles.lineClamp)}>
            {item?.description}
          </p>
        )}
      </div>
    </div>
  );
};

export default VideoGalleryItem;
