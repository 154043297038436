export const makeSvgDataSrc = ({
  width = 15,
  height = 10,
  color = "#D9D9D9",
  text,
  textColor = "#000",
}: {
  width?: number;
  height?: number;
  color?: string;
  text?: string;
  textColor?: string;
} = {}) =>
  "data:image/svg+xml;utf8," +
  encodeURIComponent(
    `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="${width}" height="${height}" fill="${color}"/>${
      text
        ? `<text x="${width / 2}" y="${
            height / 2
          }" text-anchor="middle" fill="${textColor}">${text}</text>`
        : ""
    }</svg>`,
  );

export const placeholderCrops = {
  landscape: {
    src: makeSvgDataSrc({
      width: 1000,
      height: 666,
      color: "transparent",
    }),
    srcWidth: 1000,
    srcHeight: 666,
    alt: "Missing Landscape Image",
  },
  portrait: {
    src: makeSvgDataSrc({
      width: 562,
      height: 1000,
      color: "transparent",
    }),
    srcWidth: 562,
    srcHeight: 1000,
    alt: "Missing Portrait Image",
  },
  square: {
    src: makeSvgDataSrc({
      width: 315,
      height: 315,
      color: "transparent",
    }),
    srcWidth: 315,
    srcHeight: 315,
    alt: "Missing Square Crop Image",
  },
};
