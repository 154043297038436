import { Fragment, ReactNode } from "react";
import { BaseTeaserData } from "../../Utility/dataTypes";
import {
  useSectionContext,
  SectionContextOptions,
} from "../../../hooks/useBreadCrumb/SectionsProvider";
import { Label } from "../Typography";
import styles from "./index.module.css";

export default function BreadCrumbs({
  sections,
  dateElement,
  options: optionsProp,
}: {
  sections: BaseTeaserData["sections"];
  dateElement?: ReactNode;
  options?: SectionContextOptions;
}) {
  const context = useSectionContext();
  const options = {
    depth: 1,
    ...context,
    ...optionsProp,
  };
  const displayedSections = [];

  if (sections[0]?.custom) {
    // Custom Category From Teaser Data / Content List
    displayedSections.push(sections[0]);
  } else if (!options.uniqueName) {
    // No context given, e.g. home and story page
    const parent = sections[0]?.parent;
    const grandParent = parent?.parent;
    if (grandParent && grandParent.name !== "Home") {
      displayedSections.push(grandParent);
    }
    if (parent && parent.name !== "Home") {
      displayedSections.push(parent);
    }
    if (sections[0]) {
      displayedSections.push(sections[0]);
    }
  } else {
    // E.g. section page, find child under options.uniqueName context
    const childSection = sections.find(
      (section) => section.parent?.uniqueName === options.uniqueName,
    );
    if (childSection) {
      displayedSections.push(childSection);
    }
  }

  if (!displayedSections.length && !dateElement) {
    return;
  }
  return (
    <Label>
      {displayedSections
        .slice(0, options.depth)
        .map((displayedSection, index, all) => (
          <Fragment key={displayedSection.uniqueName || index}>
            {/* ICON to show only ZH name */}
            <a href={displayedSection.path} className={styles.hyperlink}>
              {displayedSection.nameZH || displayedSection.name}
            </a>
            {(index !== all.length - 1 || dateElement) && " | "}
          </Fragment>
        ))}
      {dateElement}
    </Label>
  );
}
