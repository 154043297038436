import Arrow from "@app/theme/icons/Arrow";
import { TeaserData } from "@app/types/ServerCustomContext";
import { configCategory, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  Ads,
  AnchorBlock,
  Divider,
  ExploreMore,
  ListGroup,
  Typography,
  VideoGalleryLandscape,
} from "@sphtech/dmg-design-system";
import { NoSSR } from "@sphtech/web2-core/components";
import cx from "classnames";
import { Fragment, ReactNode } from "react";

import styles from "./category.module.css";
import CategoryStoryListing from "./CategoryStoryListing";
import CategoryStoryListingLayout from "./CategoryStoryListingLayout";
import SubAnchor from "./SubAnchor";

const { Heading, Label } = Typography;

type AnchorBlockProps = React.ComponentProps<typeof AnchorBlock> & {
  id?: string;
};

type Props = {
  data: {
    uniqSectionName?: string;
    tagSlug?: string;
    name: string;
    nameZH?: string;
    subHeading?: string;
    listGroup?: React.ComponentProps<typeof ListGroup>;
    anchorBlock?: AnchorBlockProps;
    latestArticle?: TeaserData[];
    exploreMore?: React.ComponentProps<typeof ExploreMore>;
    videoGallery?: videoGalleryLandscapeProps;
    mainContentBlock?: TeaserData[];
    lb1?: ReactNode;
    imu1?: ReactNode;
    imu2?: ReactNode;
    showVerticalLine?: boolean;
  };
};

type videoGalleryLandscapeProps = {
  title?: string;
  teasers: TeaserData[];
};

function CategoryLayout({ data }: Props): ReactNode {
  const ignoreIds = [] as string[];
  const isSectionPage = !!data.uniqSectionName;
  const mobileAdBeforeVideoGallery =
    isSectionPage && configCategory.videoGalleryLandscape?.display;

  if (data.anchorBlock?.id) {
    ignoreIds.push(data.anchorBlock.id);
  }

  if (data.latestArticle) {
    data.latestArticle.forEach((item) => {
      if (item.id) {
        ignoreIds.push(item.id);
      }
    });
  }

  if (data.mainContentBlock) {
    data.mainContentBlock.forEach((item) => {
      if (item.id) {
        ignoreIds.push(item.id);
      }
    });
  }
  return (
    <Fragment>
      {/* {Category Info} */}
      <section
        className={cx(styles.bodyContainer, styles.categoryInfoContainer)}
      >
        {/* Display Category Name */}
        {data.name && (
          <div
            className={cx(
              styles.headingContainer,
              styles[data.listGroup ? "headingWithTab" : "headingStandalone"],
              data.showVerticalLine && styles.verticalLine,
            )}
          >
            {data.subHeading && <Label Element="span">{data.subHeading}</Label>}

            <Heading.SectionTitle nameZH={data.nameZH}>
              {data.name}
            </Heading.SectionTitle>
          </div>
        )}

        {/* Display Submenu */}
        {data.listGroup && (
          <div
            className={cx(
              styles.tabContainer,
              data.showVerticalLine && styles.verticalLine,
            )}
          >
            <ListGroup {...data.listGroup} />
            <Divider />
          </div>
        )}
      </section>
      {/* AnchorBlock */}
      {data.anchorBlock && (
        <section
          className={cx(
            styles.bodyContainer,
            styles.gutterBottomOnly,
            styles.categoryAnchorBlockContainer,
          )}
        >
          <AnchorBlock
            {...data.anchorBlock}
            placeholder={placeholders.landscape}
            displayPublishDate={configTeaser.publishDate}
            mobileCrop={configTeaser.anchorBlock?.mobileCrop}
          />
        </section>
      )}

      {/* Just below AnchorBlock */}
      {data.latestArticle && (
        <section className={cx(styles.bodyContainer, styles.gutterBottom)}>
          <div className={styles.latestTeaserContainer}>
            <div className={styles.latestTeaserLayout}>
              <SubAnchor articleList={data.latestArticle} />
              {mobileAdBeforeVideoGallery && (
                <div className={styles.mobileAdvertisement}>
                  <Ads.AdsSideBarMobile />
                </div>
              )}
              {configCategory.videoGalleryLandscape?.display &&
                data.videoGallery &&
                data.videoGallery.teasers.length > 0 && (
                  <div className={styles.videoGalleryLayout}>
                    <VideoGalleryLandscape
                      title="videos"
                      items={data.videoGallery.teasers.map((item) => {
                        const crop =
                          item.image?.crops.landscape || placeholders.landscape;
                        return {
                          ...crop,
                          videoUrl: item.embedUri,
                          sections: item.sections,
                          caption: item.title,
                          captionUrl: item.path,
                          description: item.description,
                        };
                      })}
                      strip={{
                        dividerVariant:
                          configCategory.dividerVariant || "primary",
                      }}
                      Imu={false}
                      hasPagination={false}
                      hasSummaryItemPublishedDate={false}
                      mobileLoopingArrowsBelow={{
                        showArrows:
                          configCategory.videoGalleryLandscape
                            .mobileNavigation || false,
                        leftIcon: (
                          <Arrow stroke="black" direction="left" height={40} />
                        ),
                        rightIcon: <Arrow stroke="black" height={40} />,
                      }}
                    />
                  </div>
                )}
            </div>
            <div className={styles.sidebar}>
              {mobileAdBeforeVideoGallery ? (
                <Ads.AdsSideBarDesktop />
              ) : (
                <Ads.AdsSideBar />
              )}
            </div>
          </div>
        </section>
      )}

      {/* Main Content page 1 */}
      {data.mainContentBlock && data.mainContentBlock.length > 0 && (
        <CategoryStoryListingLayout
          data={{
            mainContentBlock: data.mainContentBlock,
            imu: data.imu1,
          }}
        />
      )}

      {
        <NoSSR>
          <CategoryStoryListing
            data={{
              uniqSectionName: data.uniqSectionName,
              tagSlug: data.tagSlug,
              limit: 8,
              ignoreIds: ignoreIds,
              lb1: data.lb1,
              imu: data.imu2,
            }}
          />
        </NoSSR>
      }
    </Fragment>
  );
}

export default CategoryLayout;
